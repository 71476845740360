//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import axios from "axios";
import { postFakeLogin, postJwtLogin } from "../../helpers/fakebackend_helper";
import { jwtDecode } from "jwt-decode";
import { getAuthHeader } from '../../helpers/jwt-token-access/auth-token-header'
import {
  apiError,
  subcontractorSuccess,
  singleSubcontractorSuccess
} from "./reducer";

import apiUrl from "slices/apiEndpoint";

//create property owners


export const createContSubcontractors = (sub: any) => async (dispatch: any) => {
    const headers = getAuthHeader();
  if (!headers) {
    return;
  }

  try {
    const subcontractorEndpoint = `${apiUrl}/subcontractor/create-subcontractor`;

    // Ensure getDecodeId() returns a valid value
    const contractorId = getDecodeId();
    if (!contractorId) {
      console.error("Error: Contractor ID not found");
      return;
    }

    let data = {
      subContractorFirstname: sub.firstName,
      subContractorLastName: sub.lastName,
      subContractorEmail: sub.email,
      subContractorPhone: sub.contact,
      subContractorStatus: sub.status,
      contractorId: contractorId
    };

    const resp = await axios.post(subcontractorEndpoint, data, {
     headers
    });

    if (resp && resp.data) {
      console.log(resp.data);
    } else {
      dispatch(apiError("Error creating subcontractor: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};
export const getSubcontractors = () => async (dispatch: any) => {
     const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const subcontractorEndPoint = `${apiUrl}/subcontractor/get-subcontractores/${getDecodeId()}`;

    const resp = await axios.get(subcontractorEndPoint, {headers});
    if (resp) {
      dispatch(subcontractorSuccess(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const getSingleSubcontractor = (subId: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const singleSubcontractorEndPoint = `${apiUrl}/subcontractor/get-single-subcontractor/${getDecodeId()}/${subId}`;

    const resp = await axios.get(singleSubcontractorEndPoint, {headers});
    if (resp) {
      dispatch(singleSubcontractorSuccess(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


function getDecodeId() {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode(token);
  } else {
    console.error("Token is null");
  }
  return decoded.userId;
}
