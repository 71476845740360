import React, { useEffect, useState } from "react";
import { Carousel, Image, Row, Col, Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

interface PricingProps {
  handleCancel: (values: any) => void;
  handleStartTrial: (values: any) => void;
}

interface JwtPayload {
  userType: string;
}

const PricingContractor: React.FC<PricingProps> = ({
  handleCancel,
  handleStartTrial,
}) => {
  const userSubscribed = useSelector(
    (state: any) => state.UserProfile.subscribedData
  );
  console.log(userSubscribed, "userscribe");
  const pricingdata = [
    {
      id: 1,
      price: "$4500",
      plan: "Annually",
      start: "A Saving of $200",
      variant: "success",
      button: "success",
      ribbon: true,

      actext: [
        {
          id: 1,
          act1: "Unlimited Contract Creation",
          act2: "Add Milestones",
          act3: "Set Up Payment Plans ",
          act4: "Make your own team",
          act5: "Add Sub-Contractors",
          act6: "E-Sign the Contract Document",
          act7: "Free Download",
          act8: "Anytime Anywhere access to your Unlimited Contracts",
        },
      ],
    },
    {
      id: 2,
      price: "$364",
      plan: "Monthly",
      start: "First Payment of $696",
      variant: "success",
      button: "success",
      ribbon: false,
      actext: [
        {
          id: 1,
          act1: "Unlimited Contract Creation",
          act2: "Add Milestones",
          act3: "Set Up Payment Plans ",
          act4: "Make your own team",
          act5: "Add Sub-Contractors",
          act6: "E-Sign the Contract Document",
          act7: "Free Download",
          act8: "Anytime Anywhere access to your Unlimited Contracts",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="container d-flex justify-content-center">
          <div className="col-10">
            <h2 className="text-center mb-2">Packages</h2>
            {/* <Row className="mb-4">
            <img
              className="img-fluid"
              src="https://res.cloudinary.com/dr6rh7hon/image/upload/v1719416705/banner_gydtbh.png"
            />
          </Row> */}
            <Row>
              {(pricingdata || []).map((elem) => {
                const isSubscribed =
                  userSubscribed !== null &&
                  userSubscribed.subscription.cpp_package === elem.plan;

                return (
                  <Col xxl={6} lg={6} className="g-3" key={elem.id}>
                    <Card className="pricing-box ribbon-box right">
                      {elem.ribbon && (
                        <div className="ribbon ribbon-danger vertical-shape">
                          <span>Saving Plan</span>
                        </div>
                      )}
                      <Card.Body className="p-4">
                        <div className="d-flex gap-3 align-items-center mb-4 pb-2">
                          <div className="avatar-sm flex-shrink-0">
                            <div className="avatar-title bg-success-subtle text-success fs-3 rounded">
                              <i className="ph ph-telegram-logo"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="mb-2">{elem.plan}</h5>
                            <p className="text-muted mb-0">{elem.start}</p>
                          </div>
                        </div>
                        <div className="mb-4">
                          <h3 className="mb-0 me-2">
                            {elem.price}{" "}
                            <small className="fw-normal text-muted fs-xs">
                              /{elem.plan}
                            </small>
                          </h3>
                        </div>

                        <div className="mt-4">
                          <ul className="ps-3 vstack gap-3">
                            {(elem.actext || []).map((subitem: any) => (
                              <React.Fragment key={subitem.id}>
                                {Object.keys(subitem)
                                  .slice(0, 8)
                                  .map((key) => (
                                    <li key={key} className="active">
                                      {subitem[key]}
                                    </li>
                                  ))}
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>

                        <div className="mt-4">
                          {isSubscribed &&
                          userSubscribed.subscription.cpp_is_active ===
                            "ACTIVE" ? (
                            <Button
                              className={`btn-primary w-100`}
                              onClick={() => handleCancel(elem.plan)}
                            >
                              Cancel Subscription -{" "}
                              {userSubscribed.remainingDays} days left
                            </Button>
                          ) : userSubscribed === null ||
                            userSubscribed.subscription.cpp_is_active !==
                              "ACTIVE" ? (
                            <Button
                              className={`btn-${elem.button} w-100`}
                              onClick={() => handleStartTrial(elem.plan)}
                            >
                              Start Your 30 Days Free Trial
                            </Button>
                          ) : null}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PricingContractor;
