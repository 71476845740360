import React from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import { jwtDecode } from "jwt-decode";
import { useSelector } from 'react-redux';

//Import Images
import avatar1 from "../../../../assets/images/users/avatar-1.jpg"

interface JwtPayload {
    userType: string;
    userName: string;
}

const StudentDetails = () => {
    const userProfile = useSelector((state: any) => state.UserProfile.user);
    console.log(userProfile,"userProfileuserProfile")
     const token = localStorage.getItem('authUser');
  let decoded: any = "";

  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error('Token is null');
  }
    return (
        <React.Fragment>

            <Card>
                <Card.Body>
                    <div className="text-center mb-4 pb-2">
                        <img src={userProfile.length && userProfile[0].ua_profile_pic != null ? userProfile[0].ua_profile_pic :'https://themes.themesbrand.com/steex/react/material/static/media/avatar-1.8f473ed0f0cfa8c1df8a.jpg'} alt="" className="avatar-md rounded-circle d-block mx-auto" />
                        <div className="mt-3">
                            <Link to="/pages-profile">
                                <h5>{decoded.userName}</h5>
                            </Link>
                            <p className="text-muted mb-0">{decoded.userType}</p>
                        </div>
                    </div>
                    <div className="upcoming-scheduled pt-4">
                        <Flatpickr
                            data-inline-date="true"
                            className="form-control"
                            options={{
                                dateFormat: "d M, Y",
                                inline: true
                            }}
                        />
                    </div>
                </Card.Body>
            </Card>

        </React.Fragment>
    )
}

export default StudentDetails;
