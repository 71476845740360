import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Button, Modal, Spinner, Alert } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { DeleteModal } from "Common/DeleteModal";
import AgentInfo from "./AgentInfo";
import { PatternFormat } from "react-number-format";
import {
    getAgentGridList as onGetAgentGridList,
    addAgentGridList as addAgentGridListApi,
    deleteAgentGridList as onDeleteAgentGridList
} from "slices/realestate/thunk";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup"
import { createPropertyOwner, getPropertyOwners} from '../../../slices/thunk'

const PropertyOwnerGrid = () => {

    document.title = "Property Owner";

    const dispatch = useDispatch<any>();

    const selectAgentgridList = createSelector(
        (state: any) => state.RealEstateRelibuild.propertyOwner,
        (propertyOwner) => propertyOwner
    );

    const { propertyOwner } = useSelector((state: any) => ({
        propertyOwner: selectAgentgridList(state)
    }));

    useEffect(() => {
        dispatch(getPropertyOwners());
    }, [dispatch])

    const [gridView, setGridView] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const [agentGrid, setAgentGrid] = useState<any>(null);
    const [addagent, setAddagent] = useState<boolean>(false);
    const [loading, setLoading] = useState<any>(false);
    const [editagent, setEditagent] = useState<boolean>(false);
    const [selectfeils, setSelectfeils] = useState<any>([]);
     const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

    //add agent modal
    const handleShowAgent = () => setAddagent(true);
    const handleCloseAgent = () => { setAddagent(false); setSelectfeils(null) };

    //search 
    const handlesearch = (ele: any) => {
        let search = ele.target.value;
        if (search) {
            setGridView(propertyOwner?.filter((data: any) => data.po_name.toLowerCase().includes(search.toLowerCase())))
        } else {
            setGridView(propertyOwner)
        }
    }

    const handleselect = (ele: any) => {
        console.log(ele, "ele")
        setGridView(propertyOwner?.filter((select: any) => select.po_is_active === ele || ele === "All"))
    }

    const handleDeleteShow = (ele: any) => { setShow(true); setAgentGrid(ele) };
    const handleDeleteClose = () => setShow(false);

    //delete modal
    const deleteModalFunction = () => {
        if (agentGrid.id) {
            dispatch(onDeleteAgentGridList(agentGrid.id));
        }
        setShow(false)
    }

    useEffect(() => {
        setGridView(propertyOwner)
    }, [propertyOwner])

     const handleAcceptfiles = (files: any) => {
        const newImages = files?.map((file: any) => {
            return Object.assign(file, {
                priview: URL.createObjectURL(file),
            })
        })
        setSelectfeils([...selectfeils, ...newImages]);
    }

    const formik: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: (agentGrid && agentGrid.po_id) || '',
            img: (agentGrid && JSON.parse(agentGrid.po_profile_pic)[0]) || '',
            name: (agentGrid && agentGrid.po_name) || '',
            email: (agentGrid && agentGrid.po_email) || '',
            contact: (agentGrid && agentGrid.po_phone) || '',
            status: (agentGrid && agentGrid.po_is_active) || '',
            location: (agentGrid && agentGrid.po_address) || '',
            state: (agentGrid && agentGrid.po_state) || '',
            zip:(agentGrid && agentGrid.po_zip) || '',
            country:(agentGrid && agentGrid.po_country) || '',
            altContact:(agentGrid && agentGrid.po_alt_phone) || ''
            
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Owner Name"),
            email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Owner Email"),
            contact: Yup.string().required("Please Enter Your Owner contact"),
            status: Yup.string().required("Please Enter Your Status"),
            location: Yup.string().required("Please Enter Your Location"),
            img: Yup.mixed().required("Please Enter Your Image"),
            state: Yup.string().required("Please Enter Your State"),
            zip:Yup.string().required("Please Enter Your Zip"),
            country:Yup.string().required("Please Enter Your Country"),
        }),
        onSubmit: (values: any) => {
            setLoading(true)
            if (editagent) {
                const formData = {
                    ...values
                    };
                dispatch(createPropertyOwner(formData));
                dispatch(getPropertyOwners());
                setLoading(false)
                setNotification({
        status: true,
        message: "Property Owner Updated Successfully",
        variant: "success",
      });
                formik.resetForm();
            } else {
                setLoading(true)
                  const formData = {
                    ...values
                    };
                dispatch(createPropertyOwner(formData));
                dispatch(getPropertyOwners());
                setLoading(false)
                setNotification({
        status: true,
        message:"Property Owner Created Successfully",
        variant: "success",
      });
                formik.resetForm()
            }

            if (values === null) {
                handleShowAgent();
            } else {
                handleCloseAgent();
            }
        }
    });

  const handleAlertClick = () => {
    setNotification(null);
  };
    
    return (
        <React.Fragment>
            {notification && notification.status && (
        <div
          style={{ position: "absolute", top: "5%", right: "24px", zIndex: 9 }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
            )}
            {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Property Owners" pageTitle="Property Owners" />
                    <Row >
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="align-items-center gy-3">
                                        <Col lg={3} md={6} className="order-last order-md-first me-auto">
                                            <div className="search-box">
                                                <Form.Control type="text" autoComplete="off" id="searchResultList" placeholder="Search for property owner ..." onChange={(e: any) => handlesearch(e)} />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={3} sx={12}>
                                            <div>
                                                <Form.Select id="status-input" onClick={(e: any) => handleselect(e.target.value)}>
                                                    <option value="All">Select status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Unactive">Unactive</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                        <Col className="col-auto text-end">
                                            <Button variant="secondary" onClick={handleShowAgent}><i className="bi bi-person-plus align-baseline me-1"></i> Add Property Owner</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <AgentInfo
                        data={gridView}
                        rowclassname="row-cols-xxl-5 row-cols-md-2 row-cols-1"
                        perPageData={10}
                        handleDeleteShow={handleDeleteShow} />
                </Container>
            </div>
                      )}
             <Modal show={addagent} onHide={handleCloseAgent}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5" id="addAgentModalLabel">{editagent ? "Edit Property Owner" : "Add Property Owner"}</Modal.Title>
                </Modal.Header>
                <Form className="tablelist-form" autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="mb-3">
                            <Form.Label>Property Owner Images<span className="text-danger">*</span></Form.Label>
                            <Dropzone
                                onDrop={(acceptfiles: any) => {
                                    handleAcceptfiles(acceptfiles);
                                    formik.setFieldValue("img", acceptfiles)
                                }}
                                name="img"
                                value={formik.values.img || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.img}
                            >
                                {({ getRootProps }: any) => (
                                    <div className="sellers-dropzone text-center dz-clickable" style={{ minHeight: "180px" }}>
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                            </div>
                                            <h5>Drop files here or click to upload.</h5>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            {formik.errors.img && formik.touched.img ? (
                                <Form.Control.Feedback type="invalid" className="d-block">{formik.errors.img}</Form.Control.Feedback>
                            ) : null}

                            <ul className="list-unstyled mb-0" id="dropzone-preview">
                                {

                                    (selectfeils || [])?.map((file: any, index: number) => {
                                        return (
                                            <li className="mt-2 dz-image-preview" key="">
                                                <div className="border rounded">
                                                    <div className="d-flex flex-wrap gap-2 p-2">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar-sm bg-light rounded p-2">
                                                                <img data-dz-thumbnail="" className="img-fluid rounded d-block" src={file.priview} alt={file.name} />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="pt-1">
                                                                <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                                                                <p className="fs-sm text-muted mb-0" data-dz-size=""><strong>{file?.size?.toString()?.charAt(0)}</strong> KB</p>
                                                                <strong className="error text-danger" data-dz-errormessage></strong>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-3">
                                                            <Button variant="danger" size="sm" onClick={() => {
                                                                const newImages = [...selectfeils];
                                                                newImages.splice(index, 1);
                                                                setSelectfeils(newImages);
                                                            }}>Delete</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="agent-name-input">Property Owner Name<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter agent name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.name}
                            />
                            {formik.errors.name && formik.touched.name ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="email-input" >Email<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.email}
                            />
                            {formik.errors.email && formik.touched.email ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="contact-input" >Contact Number<span className="text-danger">*</span></Form.Label>
                            <PatternFormat
                                className="form-control"
                                displayType="input"
                                id="contact"
                                placeholder="Enter contact no"
                                name="contact"
                                format="(##) #####-#####"
                                value={formik.values.contact}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.contact && formik.touched.contact ? (
                                <Form.Control.Feedback type="invalid" className="d-block">{formik.errors.contact}</Form.Control.Feedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="contact-input" >Alternate Contact Number<span className="text-danger">*</span></Form.Label>
                            <PatternFormat
                                className="form-control"
                                displayType="input"
                                id="altContact"
                                placeholder="Enter contact no"
                                name="altContact"
                                format="(##) #####-#####"
                                value={formik.values.altContact}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.altContact && formik.touched.altContact ? (
                                <Form.Control.Feedback type="invalid" className="d-block">{formik.errors.altContact}</Form.Control.Feedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="status-type-input">Status<span className="text-danger">*</span></Form.Label>
                            <Form.Select
                                className="form-control"
                                id="status-type-input"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.status}
                            >
                                <option value="">select Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Select>
                            {formik.errors.status && formik.touched.status ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.status}</Form.Control.Feedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Form.Label htmlFor="address-input">Street Address<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control"
                                id="addressinput"
                                name="location"
                                placeholder=" Enter address"
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.location}
                            />
                            {formik.errors.location && formik.touched.location ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.location}</Form.Control.Feedback>
                            ) : null}
                        </div>
                          <div className="mb-3">
                            <Form.Label htmlFor="agent-name-input">State<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="state"
                                name="state"
                                placeholder="Enter State"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.state}
                            />
                            {formik.errors.state && formik.touched.state ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.state}</Form.Control.Feedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Form.Label htmlFor="agent-name-input">Zip<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="zip"
                                name="zip"
                                placeholder="Enter Zip"
                                value={formik.values.zip}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.zip}
                            />
                            {formik.errors.zip && formik.touched.zip ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.zip}</Form.Control.Feedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Form.Label htmlFor="agent-name-input">Country<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                id="country"
                                name="country"
                                placeholder="Enter Country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.country}
                            />
                            {formik.errors.country && formik.touched.country ? (
                                <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-ghost-danger" onClick={handleCloseAgent}><i className="bi bi-x-lg align-baseline me-1"></i> Close </Button>
                        <Button type="submit" variant="primary" id="add-btn">{editagent ? "Edit" : "Add"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <DeleteModal show={show} handleClose={handleDeleteClose} deleteModalFunction={deleteModalFunction} />
            <ToastContainer />
        </React.Fragment>
    );
}

export default PropertyOwnerGrid;