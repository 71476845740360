import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  createChangeOrder,
  getSingleProjectById,
} from "../../../../slices/thunk";
import { useSelector, useDispatch } from "react-redux";

const ChangeOrder: React.FC = () => {
  const [selectedState, setSelectedState] = useState("");
  const dispatch: any = useDispatch();
  const project = useSelector((state: any) => state.Contract.project);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
    
    useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  const validationSchema = Yup.object().shape({
    additionalHomeownerName: Yup.string(),
    descriptionOfWork: Yup.string().required("Description of work is required"),
    documentName: Yup.string().required("Document name is required"),
    priceForWork: Yup.string().required("Price Of Work is required"),
    additionalTimeToComplete: Yup.string().required(
      "Additional Time To Complete Of Work is required"
    ),
    netChangePrior: Yup.string().required("Net Change Prior is required"),
    adjustedGrossContractPrior: Yup.string().required(
      "Adjusted Gross Contract Prior is required"
    ),
    adjustedGrossContractTotal: Yup.string().required(
      "Adjusted Gross Contract Total  is required"
    ),
  });

  const validation = useFormik({
    initialValues: {
      projectId: project?.p_id || "",
      homeownerName: project?.home_owner
        ? `${project.home_owner.user_first_name} ${project.home_owner.user_last_name}`
        : "",
      additionalHomeownerName:
        project?.contract?.pc_additional_home_owner_name || "",
      contractorName: project?.contractor
        ? `${project.contractor.user_first_name} ${project.contractor.user_last_name}`
        : "",
      projectAddress: project?.p_street_address || "",
      city: project?.p_city || "",
      state: project?.p_state || "",
      zip: project?.p_zip || "",
      totalSalesPrice: project?.p_total_cost || "",
      descriptionOfWork: project?.contract?.pc_work_description || "",
      priceForWork: project?.change_orders?.priceForWork || "",
      additionalTimeToComplete:
        project?.change_orders?.additionalTimeToComplete || "",
      netChangePrior: project?.change_orders?.netChangePrior || "",
      adjustedGrossContractPrior:
        project?.change_orders?.adjustedGrossContractPrior || "",
      adjustedGrossContractTotal:
        project?.change_orders?.adjustedGrossContractTotal || "",

      homeownerId: project?.p_home_owner_id || "",
      documentName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await dispatch(createChangeOrder(values));
        if (project !== "") {
          dispatch(getSingleProjectById(project.p_id));
        }
        setNotification({
          status: true,
          message: "Change Order Created Successfully",
          variant: "success",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error
        console.error("Error creating contract:", error);
      }
    },
  });

  return (
    <React.Fragment>
       {notification && notification.status && (
        <div style={{position:'absolute', top:'5%', right: '24px', zIndex:9}}>
          <Alert variant={notification.variant} onClick={handleAlertClick}>{notification.message}</Alert>
          </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="">
          <Container>
            <Row className="p-5">
              <Col>
                <h4>Add Change Order Contract</h4>
              </Col>
            </Row>
          </Container>
          <Form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Container>
              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Homeowner details</h5>
                  <p>
                    Most details are already prefilled by a homeowner. You can
                    add additional homeowner name if you wish.
                  </p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="homeownerName"
                        name="homeownerName"
                        disabled
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.homeownerName || ""}
                        isInvalid={
                          validation.touched.homeownerName &&
                          !!validation.errors.homeownerName
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.homeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Additional Homeowner Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="additionalHomeownerName"
                        value={validation.values.additionalHomeownerName || ""}
                        onChange={validation.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.additionalHomeownerName &&
                          !!validation.errors.additionalHomeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Contractor details</h5>
                  <p>All necessary details are already prefilled.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="contractorName"
                        name="contractorName"
                        value={validation.values.contractorName || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h3>Project address</h3>
                  <p>All necessary details are already prefilled by you.</p>
                  <Form.Group className="mb-3">
                    <Form.Label>Street address</Form.Label>
                    <Form.Control
                      type="text"
                      id="projectAddress"
                      name="projectAddress"
                      value={validation.values.projectAddress || ""}
                      disabled
                      onChange={validation.handleChange}
                    />
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          id="city"
                          name="city"
                          value={validation.values.city || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          id="state"
                          name="state"
                          value={validation.values.state || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type="text"
                          id="zip"
                          name="zip"
                          value={validation.values.zip || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Total Sales Price *</h5>
                  <p>All necessary details are already prefilled by you.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Total Sales Price *</Form.Label>
                      <Form.Control
                        type="text"
                        id="totalSalesPrice"
                        name="totalSalesPrice"
                        value={validation.values.totalSalesPrice || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <h2>Scope of work & Accounting sumary</h2>
              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Description of work*</Form.Label>
                    <Form.Control
                      as="textarea"
                      id="descriptionOfWork"
                      name="descriptionOfWork"
                      value={validation.values.descriptionOfWork || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.descriptionOfWork && (
                      <div className="text-danger">
                        {validation.errors.descriptionOfWork &&
                          !!validation.errors.descriptionOfWork}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Price for work above *</Form.Label>
                    <Form.Control
                      type="text"
                      id="priceForWork"
                      name="priceForWork"
                      value={validation.values.priceForWork || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.priceForWork && (
                      <div className="text-danger">
                        {validation.errors.priceForWork &&
                          !!validation.errors.priceForWork}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Additional time to complete the project *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="additionalTimeToComplete"
                      name="additionalTimeToComplete"
                      value={validation.values.additionalTimeToComplete || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.additionalTimeToComplete && (
                      <div className="text-danger">
                        {validation.errors.additionalTimeToComplete &&
                          !!validation.errors.additionalTimeToComplete}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Net change by all prior change orders *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="netChangePrior"
                      name="netChangePrior"
                      value={validation.values.netChangePrior || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.netChangePrior && (
                      <div className="text-danger">
                        {validation.errors.netChangePrior &&
                          !!validation.errors.netChangePrior}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Adjusted gross contract total prior to this Change Order *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="adjustedGrossContractPrior"
                      name="adjustedGrossContractPrior"
                      value={validation.values.adjustedGrossContractPrior || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.adjustedGrossContractPrior && (
                      <div className="text-danger">
                        {validation.errors.adjustedGrossContractPrior &&
                          !!validation.errors.adjustedGrossContractPrior}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Adjusted gross contract total including this Change Order
                      *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="adjustedGrossContractTotal"
                      name="adjustedGrossContractTotal"
                      value={validation.values.adjustedGrossContractTotal || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.adjustedGrossContractTotal && (
                      <div className="text-danger">
                        {validation.errors.adjustedGrossContractTotal &&
                          !!validation.errors.adjustedGrossContractTotal}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-3 rounded">
                <Col>
                  <h5>Document name *</h5>
                  <Row>
                    <p>
                      Please indicate what you would like the contract’s PDF
                      file name to be. This name will be shared with all signing
                      parties *
                    </p>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          id="documentName"
                          name="documentName"
                          value={validation.values.documentName || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.errors.documentName && (
                          <div className="text-danger">
                            {validation.errors.documentName}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="p-4 rounded">
                <Col className="d-flex justify-content-center">
                  <Button type="submit" variant="primary">
                    Create Contract
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};

export default ChangeOrder;
