import React, { useEffect, useState } from 'react';
import { Container, Row, Spinner, Alert, Col, Table, Form, Button } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { useSelector, useDispatch } from 'react-redux';
import { getAllContractors, approveContractor } from '../../../slices/thunk';
import { Link } from 'react-router-dom';

const AdminContratorsData = () => {
  document.title = 'Contractor Data';
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ show: false, variant: '', message: '' }); // Notification state

  const allcontractor = useSelector((state: any) => state.Admin.contractors);
  
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getAllContractors())
  },[])
const handleSwitchToggle = async (id: string, event: any) => {
  try {
    const isChecked = event;
    const newValue = isChecked === 1 ? 0 : 1;
    dispatch(approveContractor(isChecked, id))
    dispatch(getAllContractors())
  } catch (error) {
    console.error('Error updating switch:', error);
    // Handle error, e.g., show error message to the user
  }
};

  return (
    <React.Fragment>
      
      <div className="page-content">
        {notification.show && (
        <Alert variant={notification.variant} onClose={() => setNotification({ show: false, variant: '', message: '' })} dismissible>
          {notification.message}
        </Alert>
      )}
        <Container fluid>
          <BreadCrumb title="Contractors" pageTitle="Pages" />
          <Row>
           <Col xl={12}>
    <div className="table-responsive mt-4 mt-xl-0">
        <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope='col'>Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">View</th>
                      </tr>
                      
            </thead>
                  <tbody>
                  {allcontractor.length && allcontractor.map((item: any) => (
            
                <tr key={item.id}>
                        <td className="fw-medium">{ item.id}</td>
                    <td>{item.user_first_name}</td>
                        <td>{ item.user_last_name}</td>
                        <td>{item.user_email}</td>
                        <td>{ item.user_phone}</td>
                        <td><i className="ri-checkbox-circle-line align-middle text-success"></i> {item.is_active === 0 ? 'No' : 'Yes' }</td>
                    <td>
                        <div className="form-check form-switch">
                            <Form.Check 
                              className="form-switch"
                              type="switch"
                              id={`SwitchCheck-${item.id}`}
                            onChange={(e) => handleSwitchToggle(item.id, e.target.checked)}
                            checked={item.is_active ===1 ? true :false}
                            />
                            <Form.Label className="ms-2" htmlFor="SwitchCheck1">No/Yes</Form.Label>
                        </div>
                      </td>
                      <td>
                        <Link to={`/pages-single-contractors?id=${item.id}`} className="text-reset">
                          <Button>View</Button>
                          </Link>
                      </td>
                      </tr>
                    
                      ))}
              
            </tbody>
        </Table>
    </div>
</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminContratorsData;
