import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { jwtDecode } from "jwt-decode";
import { WidgetsChart } from './charts'
import { HomeOwner, Admin, Legel } from 'Common/data'
import { getAllContractors} from '../../../slices/thunk';
interface JwtPayload {
  userType: string;
}
const Widgets = () => {
  const dispatch: any = useDispatch();
  useEffect(() => { 
    dispatch(getAllContractors())
  }, [])
  const countContractor = useSelector((state: any) => state.Admin.contractorsCount);
  const token = localStorage.getItem('authUser')
  let decoded: any = "";
  if (token !== null) {
       decoded = jwtDecode<JwtPayload>(token);
  
    } else {
      console.error('Token is null');
    }
let user:any = "";
    if (decoded.userType === 'homeowner'){
      user = HomeOwner
    }
    else if(decoded.userType === 'contractor'){
      user = HomeOwner
    }
    else if(decoded.userType === 'legel'){
      user = Legel
    }
    else {
      user = Admin
    }
  return (
    <React.Fragment>
      <Row>
        {(user || []).map((item: any, key: number) => (
          <Col xxl={3} md={6} key={key}>
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div className="d-flex flex-column h-100">
                      <p className="fs-md text-muted mb-4">{item.name}</p>
                      <h3 className="mb-0 mt-auto">
                        <span>
                        <CountUp start={0} end={decoded.userType === 'admin' ? parseInt(countContractor) :  item.amount} suffix={item.suffix} decimals={item.decimal && 2} /> {" "}
                        </span>
                        <small className={item.isArrow ? "text-success mb-0 fs-xs" : "text-danger mb-0 fs-xs"}>
                          <i className={item.isArrow ? "bi bi-arrow-up me-1" : "bi bi-arrow-down me-1"}></i> {item.perstange}
                        </small>
                      </h3>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <WidgetsChart periodData={item.widgetsSeries} dataColors={item.dataColors} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Widgets;
