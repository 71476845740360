import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Delaware from "../Delaware";
import Florida from "../Florida";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import {
  createSubContract,
  getSingleProjectById,
} from "../../../../../slices/thunk";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

interface AddSubContractProps {
  subContractorId: any;
  handleSubmition: (type: boolean) => void;
}

interface SubContractors {
  ps_subcontractor_id: string;
  user_first_name: string;
  user_last_name: string;
}

interface Project {
  subcontractors: SubContractors[];
  // Add other properties as needed
}
const AddSubContract: React.FC<AddSubContractProps> = ({
  subContractorId,
  handleSubmition,
}) => {
  const [selectedState, setSelectedState] = useState("");
  const dispatch: any = useDispatch();
  let data = null;
  const project = useSelector((state: any) => state.Contract.project);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  type AnyObject = Record<string, any>;

  const validationSchema = Yup.object().shape({
    documentName: Yup.string().required("Document name is required"),
  });

  const getFilteredSubcontractor = (
    subcontractors: any,
    subContractorId: any
  ) => {
    return subcontractors.find(
      (sub: any) => sub.ps_subcontractor_id === subContractorId
    );
  };

  const filteredSubcontractor = getFilteredSubcontractor(
    project.subcontractors,
    subContractorId
  );

  const validation = useFormik({
    initialValues: {
      projectId: project.p_id,
      subContractorId: subContractorId as String,
      homeownerName: project?.home_owner
        ? `${project.home_owner.user_first_name} ${project.home_owner.user_last_name}`
        : "",
      additionalHomeownerName: "",
      contractorName: project?.contractor
        ? `${project.contractor.user_first_name} ${project.contractor.user_last_name}`
        : "",
      projectAddress: project?.p_street_address || "",
      city: project?.p_city || "",
      state: project?.p_state || "",
      zip: project?.p_zip || "",
      subcontractorStartDate: null,
      insuranceProviderName: "",
      totalSalesPrice: project?.p_cost || "",
      allowances: "",
      subcontractorResponsibilities: [""],
      descriptionOfWork: "",

      //subContractorName
      subContractorFirstName:
        project?.subcontractors.find(
          (sub: SubContractors) => sub.ps_subcontractor_id === subContractorId
        )?.user_first_name || "",
      subContractorLastName:
        project?.subcontractors.find(
          (sub: SubContractors) => sub.ps_subcontractor_id === subContractorId
        )?.user_last_name || "",
      //subContractorName

      //subContractorCompanyName
      subContractorCompanyName:
        project?.subcontractors.find(
          (sub: SubContractors) => sub.ps_subcontractor_id === subContractorId
        )?.company_name || "",
      //subContractorCompanyName
      //contractorCompanyName
      contractorCompanyName: project?.contractor.company_name,
      //contractorCompanyName

      //completion date
      completionDateOption: "",
      completionDateSpecific: null,
      completionDateOther: null,
      //completion ends date
      //sub date
      substantialCompletionDateOption: "",
      substantialCompletionDateSpecific: null,
      substantialCompletionDateOther: null,
      //sub ends date

      //Payment Amount options
      paymentAmountOption: "",
      totalAmount: "",
      hourlyRate: "",
      otherDescription: "",
      //Payment Amount options ends

      //Payment method oopto
      paymentMethodOption: "",
      otherPaymentMethod: "",
      //Payment method ends oopto

      //payment validation start
      paymentFrequencyOption: "",
      otherPaymentFrequency: "",

      //payment validation ends

      //right to subcontract
      rightToSubcontract: "",
      //right to ends  subcontract

      //assignemnt rights
      assignmentRight: "",
      //assignemnt rights

      //subcontractor
      subContractorInsurance: "",
      subInsuranceProviderName: "",
      subWorkersCompensationInsurance: "",
      subWorkersCompensationInsuranceLimit: "",
      //subcton insurance ends

      //aditionalprovi
      additionalProvision: "",
      //aditionalprovi

      //descriptionOfServices
      descriptionOfServices: "",
      //descriptionOfServices

      //subcontractorAuthorizedRepresentative
      subcontractorAuthorizedRepresentative: "",
      //subcontractorAuthorizedRepresentative

      escrowService: project?.p_escrow === "1",
      authorizedRepresentative: "",
      contractorAuthorizedRepresentative: "",
      contractorInsurance: "",
      workersCompensationInsurance: "",
      workersCompensationInsuranceLimit: "",
      homeownerId: project?.p_home_owner_id || "",
      documentName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await dispatch(createSubContract(values));
        if (project !== "") {
          setLoading(true);
          dispatch(getSingleProjectById(project.p_id)).finally(() =>
            setLoading(false)
          );
        }
        handleSubmition(true);
        setNotification({
          status: true,
          message: "Contract Created Successfully",
          variant: "success",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error
        console.error("Error creating contract:", error);
      }
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedResponsibilities: any = [
      ...validation.values.subcontractorResponsibilities,
    ];

    if (checked && !updatedResponsibilities.includes(value)) {
      updatedResponsibilities.push(value); // Add the value to array if checked
    } else {
      const index = updatedResponsibilities.indexOf(value);
      if (index !== -1) {
        updatedResponsibilities.splice(index, 1); // Remove the value from array if unchecked
      }
    }

    // Update formik's state with the updated array
    validation.setFieldValue(
      "subcontractorResponsibilities",
      updatedResponsibilities
    );
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{ position: "absolute", top: "5%", right: "24px", zIndex: 9 }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="">
          <Container>
            <Row className="p-5">
              <Col>
                <h4>ADD SUBCONTRACTOR CONTRACT</h4>
                <h5>Home Improvement Contract</h5>
              </Col>
            </Row>
          </Container>
          <Form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Container>
              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Contract type</h5>
                  <p>Governing Law State *</p>
                  <Col lg={4}>
                    <Form.Select
                      className="fs-5"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      <option value="">Select State</option>
                      <option value="CT">Connecticut</option>
                      <option value="FL" disabled>
                        Florida
                      </option>
                      <option value="MA" disabled>
                        Massachusetts
                      </option>
                      <option value="DE" disabled>
                        Delaware
                      </option>
                      <option value="OH">Ohio</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Homeowner details</h5>
                  <p>
                    Most details are already prefilled by a homeowner. You can
                    add additional homeowner name if you wish.
                  </p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="homeownerName"
                        name="homeownerName"
                        disabled
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.homeownerName || ""}
                        isInvalid={
                          validation.touched.homeownerName &&
                          !!validation.errors.homeownerName
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.homeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Additional Homeowner Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="additionalHomeownerName"
                        value={validation.values.additionalHomeownerName || ""}
                        onChange={validation.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.additionalHomeownerName &&
                          !!validation.errors.additionalHomeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Contractor details</h5>
                  <p>All necessary details are already prefilled.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="contractorName"
                        name="contractorName"
                        value={validation.values.contractorName || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Contractor Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="contractorCompanyName"
                        name="contractorCompanyName"
                        value={validation.values.contractorCompanyName || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Subcontractor details</h5>
                  <p>
                    All necessary details are already prefilled by
                    Subcontractor.
                  </p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="subContractorName"
                        name="subContractorName"
                        value={`${validation.values.subContractorFirstName || ""} ${validation.values.subContractorLastName || ""}`}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Subcontractor Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="subContractorCompanyName"
                        name="subContractorCompanyName"
                        value={validation.values.subContractorCompanyName || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h3>Project address</h3>
                  <p>All necessary details are already prefilled by you.</p>
                  <Form.Group className="mb-3">
                    <Form.Label>Street address</Form.Label>
                    <Form.Control
                      type="text"
                      id="projectAddress"
                      name="projectAddress"
                      value={validation.values.projectAddress || ""}
                      disabled
                      onChange={validation.handleChange}
                    />
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          id="city"
                          name="city"
                          value={validation.values.city || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          id="state"
                          name="state"
                          value={validation.values.state || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type="text"
                          id="zip"
                          name="zip"
                          value={validation.values.zip || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Total Sales Price *</h5>
                  <p>All necessary details are already prefilled by you.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Total Sales Price *</Form.Label>
                      <Form.Control
                        type="text"
                        id="totalSalesPrice"
                        name="totalSalesPrice"
                        value={validation.values.totalSalesPrice || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={10}>
                  <h5>Allowances</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Total allowances</Form.Label>
                    <Form.Control
                      type="text"
                      id="allowances"
                      name="allowances"
                      value={validation.values.allowances || ""}
                      onChange={validation.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.allowances &&
                        !!validation.errors.allowances}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={6}>
                  <h5>Contractor’s Insurance *</h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Carries commercial general liability insurance"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="yes"
                    checked={validation.values.contractorInsurance === "yes"}
                    onChange={validation.handleChange}
                  />
                  {validation.values.contractorInsurance === "yes" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Insurance Provider Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="insuranceProviderName"
                        value={validation.values.insuranceProviderName}
                        onChange={validation.handleChange}
                      />
                      {validation.errors.insuranceProviderName &&
                        validation.touched.insuranceProviderName && (
                          <div className="text-danger">
                            {validation.errors.insuranceProviderName &&
                              !!validation.errors.insuranceProviderName}
                          </div>
                        )}
                    </Form.Group>
                  )}
                  <Form.Check
                    label="The Contractor does not carry commercial general liability insurance"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="no"
                    checked={validation.values.contractorInsurance === "no"}
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="The Contractor is self-insured"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="selfInsured"
                    checked={
                      validation.values.contractorInsurance === "selfInsured"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.errors.contractorInsurance &&
                    validation.touched.contractorInsurance && (
                      <div className="text-danger">
                        {validation.errors.contractorInsurance &&
                          !!validation.errors.contractorInsurance}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Worker's Compensation Insurance *</h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Contractor has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations"
                    name="workersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="noEmployees"
                    checked={
                      validation.values.workersCompensationInsurance ===
                      "noEmployees"
                    }
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="Contractor carries Workers’ Compensation Insurance for all employees"
                    name="workersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="hasInsurance"
                    checked={
                      validation.values.workersCompensationInsurance ===
                      "hasInsurance"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.values.workersCompensationInsurance ===
                    "hasInsurance" && (
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Worker's Compensation Insurance Limit *
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="workersCompensationInsuranceLimit"
                            value={
                              validation.values
                                .workersCompensationInsuranceLimit
                            }
                            onChange={validation.handleChange}
                          />
                          {validation.errors
                            .workersCompensationInsuranceLimit &&
                            validation.touched
                              .workersCompensationInsuranceLimit && (
                              <div className="text-danger">
                                {validation.errors
                                  .workersCompensationInsuranceLimit &&
                                  !!validation.errors
                                    .workersCompensationInsuranceLimit}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {validation.errors.workersCompensationInsurance &&
                    validation.touched.workersCompensationInsurance && (
                      <div className="text-danger">
                        {validation.errors.workersCompensationInsurance &&
                          !!validation.errors.workersCompensationInsurance}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={10}>
                  <h5>Description of services*</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Description of Services to Be Provided By Subcontractor *
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      id="descriptionOfServices"
                      name="descriptionOfServices"
                      value={validation.values.descriptionOfServices || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.descriptionOfServices && (
                      <div className="text-danger">
                        {validation.errors.descriptionOfServices &&
                          !!validation.errors.descriptionOfServices}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={5}>
                  <h5>Authorized Representatives</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Contractor Authorized Representative First and Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="contractorAuthorizedRepresentative"
                      name="contractorAuthorizedRepresentative"
                      value={
                        validation.values.contractorAuthorizedRepresentative ||
                        ""
                      }
                      onChange={validation.handleChange}
                    />
                    {validation.errors.contractorAuthorizedRepresentative && (
                      <div className="text-danger">
                        {validation.errors.contractorAuthorizedRepresentative &&
                          !!validation.errors
                            .contractorAuthorizedRepresentative}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Subcontractor Authorized Representative First and Last
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="subcontractorAuthorizedRepresentative"
                      name="subcontractorAuthorizedRepresentative"
                      value={
                        validation.values
                          .subcontractorAuthorizedRepresentative || ""
                      }
                      onChange={validation.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Subcontractor Responsibilities *</h5>
                  <p>You can select multiple options.</p>
                  <Form.Check
                    label="Labor. Including, but not limited to, employees, subcontractors and any other individuals or agents."
                    name="labor"
                    type="checkbox"
                    className="mb-3"
                    value="labor"
                    checked={validation.values.subcontractorResponsibilities.includes(
                      "labor"
                    )}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    label="Materials. Including, but not limited to, all supplies and products."
                    name="materials"
                    type="checkbox"
                    className="mb-3"
                    value="materials"
                    checked={validation.values.subcontractorResponsibilities.includes(
                      "materials"
                    )}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    label="Equipment. Including, but not limited to, machinery, accessories, or devices."
                    name="equipment"
                    type="checkbox"
                    className="mb-3"
                    value="equipment"
                    checked={validation.values.subcontractorResponsibilities.includes(
                      "equipment"
                    )}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    label="Travel. Including, but not limited to, ensuring that the above-mentioned Responsibilities are provided at the Location mentioned in Section V."
                    name="travel"
                    type="checkbox"
                    className="mb-3"
                    value="travel"
                    checked={validation.values.subcontractorResponsibilities.includes(
                      "travel"
                    )}
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    label="Other"
                    name="other"
                    type="checkbox"
                    className="mb-3"
                    value="other"
                    checked={validation.values.subcontractorResponsibilities.includes(
                      "other"
                    )}
                    onChange={handleCheckboxChange}
                  />
                  {validation.values.subcontractorResponsibilities.includes(
                    "other"
                  ) && (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Description of Other Responsibilities *
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        id="descriptionOfWork"
                        name="descriptionOfWork"
                        value={validation.values.descriptionOfWork}
                        onChange={validation.handleChange}
                      />
                      {validation.errors.descriptionOfWork && (
                        <div className="text-danger">
                          {validation.errors.descriptionOfWork}
                        </div>
                      )}
                    </Form.Group>
                  )}
                  {validation.errors.subcontractorResponsibilities &&
                    validation.touched.subcontractorResponsibilities && (
                      <div className="text-danger">
                        {validation.errors.subcontractorResponsibilities}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Subcontractor Start Date*</h5>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Subcontractor Start Date*</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={validation.values.subcontractorStartDate}
                        onChange={(date) =>
                          validation.setFieldValue(
                            "subcontractorStartDate",
                            date
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.subcontractorStartDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Completion Date Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="By the specific date of"
                      type="radio"
                      name="completionDateOption"
                      value="specificDate"
                      checked={
                        validation.values.completionDateOption ===
                        "specificDate"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.completionDateOption ===
                      "specificDate" && (
                      <Form.Group className="mb-3">
                        <Form.Label>Completion Date - Specific *</Form.Label>
                        <DatePicker
                          className="form-control"
                          selected={validation.values.completionDateSpecific}
                          onChange={(date) =>
                            validation.setFieldValue(
                              "completionDateSpecific",
                              date
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.completionDateSpecific}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Check
                      label="In accordance with industry standards"
                      type="radio"
                      name="completionDateOption"
                      value="industryStandards"
                      checked={
                        validation.values.completionDateOption ===
                        "industryStandards"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Other"
                      type="radio"
                      name="completionDateOption"
                      value="other"
                      checked={
                        validation.values.completionDateOption === "other"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.completionDateOption === "other" && (
                      <Form.Group className="mb-3">
                        <Form.Label>Completion Date - Other *</Form.Label>
                        <DatePicker
                          className="form-control"
                          selected={validation.values.completionDateOther}
                          onChange={(date) =>
                            validation.setFieldValue(
                              "completionDateOther",
                              date
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.completionDateOther}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.completionDateOption &&
                        validation.touched.completionDateOption &&
                        validation.errors.completionDateOption}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Substantial Completion Date Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="By the specific date of"
                      type="radio"
                      name="substantialCompletionDateOption"
                      value="specificDate"
                      checked={
                        validation.values.substantialCompletionDateOption ===
                        "specificDate"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.substantialCompletionDateOption ===
                      "specificDate" && (
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Substantial Completion Date - Specific *
                        </Form.Label>
                        <DatePicker
                          className="form-control"
                          selected={
                            validation.values.substantialCompletionDateSpecific
                          }
                          onChange={(date) =>
                            validation.setFieldValue(
                              "substantialCompletionDateSpecific",
                              date
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.substantialCompletionDateSpecific}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Check
                      label="In accordance with industry standards"
                      type="radio"
                      name="substantialCompletionDateOption"
                      value="industryStandards"
                      checked={
                        validation.values.substantialCompletionDateOption ===
                        "industryStandards"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Other"
                      type="radio"
                      name="substantialCompletionDateOption"
                      value="other"
                      checked={
                        validation.values.substantialCompletionDateOption ===
                        "other"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.substantialCompletionDateOption ===
                      "other" && (
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Substantial Completion Date - Other *
                        </Form.Label>
                        <DatePicker
                          className="form-control"
                          selected={
                            validation.values.substantialCompletionDateOther
                          }
                          onChange={(date) =>
                            validation.setFieldValue(
                              "substantialCompletionDateOther",
                              date
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.substantialCompletionDateOther}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.substantialCompletionDateOption &&
                        validation.touched.substantialCompletionDateOption &&
                        validation.errors.substantialCompletionDateOption}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Escrow Service</h5>
                  <p className="mb-2">Escrow</p>
                  <Form.Check
                    disabled
                    type="switch"
                    id="escrowService"
                    name="escrowService"
                    label="Enable Escrow Service"
                    checked={validation.values.escrowService}
                    onChange={validation.handleChange}
                  />
                  {validation.errors.escrowService &&
                    validation.touched.escrowService && (
                      <div className="text-danger">
                        {validation.errors.escrowService}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Payment Amount Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="Total Amount"
                      type="radio"
                      name="paymentAmountOption"
                      value="totalAmount"
                      checked={
                        validation.values.paymentAmountOption === "totalAmount"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.paymentAmountOption ===
                      "totalAmount" && (
                      <Form.Group className="mb-3">
                        <Form.Label>Payment Amount - Total Amount *</Form.Label>
                        <Form.Control
                          type="text"
                          id="totalAmount"
                          name="totalAmount"
                          value={validation.values.totalAmount}
                          onChange={validation.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.totalAmount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Check
                      label="Hourly Rate"
                      type="radio"
                      name="paymentAmountOption"
                      value="hourlyRate"
                      checked={
                        validation.values.paymentAmountOption === "hourlyRate"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.paymentAmountOption === "hourlyRate" && (
                      <Form.Group className="mb-3">
                        <Form.Label>Payment Amount - Hourly Rate *</Form.Label>
                        <Form.Control
                          type="text"
                          id="hourlyRate"
                          name="hourlyRate"
                          value={validation.values.hourlyRate}
                          onChange={validation.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.hourlyRate}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Check
                      label="Other"
                      type="radio"
                      name="paymentAmountOption"
                      value="other"
                      checked={
                        validation.values.paymentAmountOption === "other"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.paymentAmountOption === "other" && (
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Payment Amount - Other Description *
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          id="otherDescription"
                          name="otherDescription"
                          value={validation.values.otherDescription}
                          onChange={validation.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.otherDescription}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {validation.errors.paymentAmountOption &&
                        validation.touched.paymentAmountOption &&
                        validation.errors.paymentAmountOption}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Payment Method Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="Immediately upon completion of the Services to the satisfaction of the Contractor."
                      type="radio"
                      name="paymentMethodOption"
                      value="immediate"
                      checked={
                        validation.values.paymentMethodOption === "immediate"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Within (7) business days after completion of the Services to the satisfaction of the Contractor."
                      type="radio"
                      name="paymentMethodOption"
                      value="within7days"
                      checked={
                        validation.values.paymentMethodOption === "within7days"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Weekly/monthly/quarterly/other regular payment"
                      type="radio"
                      name="paymentMethodOption"
                      value="regular"
                      checked={
                        validation.values.paymentMethodOption === "regular"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Other"
                      type="radio"
                      name="paymentMethodOption"
                      value="other"
                      checked={
                        validation.values.paymentMethodOption === "other"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    {validation.values.paymentMethodOption === "other" && (
                      <Form.Group className="mb-3">
                        <Form.Label>Payment Method Option - Other *</Form.Label>
                        <Form.Control
                          type="text"
                          id="otherPaymentMethod"
                          name="otherPaymentMethod"
                          value={validation.values.otherPaymentMethod}
                          onChange={validation.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.otherPaymentMethod}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {validation.errors.paymentMethodOption &&
                        validation.touched.paymentMethodOption &&
                        validation.errors.paymentMethodOption}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>
              {validation.values.paymentMethodOption === "regular" && (
                <Row className="bg-white p-5 mb-5 rounded">
                  <Col>
                    <h5>Payment Frequency Options *</h5>
                    <p>Choose one option.</p>
                    <Col>
                      <Form.Check
                        label="Weekly"
                        type="radio"
                        name="paymentFrequencyOption"
                        value="weekly"
                        checked={
                          validation.values.paymentFrequencyOption === "weekly"
                        }
                        onChange={validation.handleChange}
                        className="mb-3"
                      />

                      <Form.Check
                        label="Monthly"
                        type="radio"
                        name="paymentFrequencyOption"
                        value="monthly"
                        checked={
                          validation.values.paymentFrequencyOption === "monthly"
                        }
                        onChange={validation.handleChange}
                        className="mb-3"
                      />

                      <Form.Check
                        label="Quarterly"
                        type="radio"
                        name="paymentFrequencyOption"
                        value="quarterly"
                        checked={
                          validation.values.paymentFrequencyOption ===
                          "quarterly"
                        }
                        onChange={validation.handleChange}
                        className="mb-3"
                      />

                      <Form.Check
                        label="Other"
                        type="radio"
                        name="paymentFrequencyOption"
                        value="other"
                        checked={
                          validation.values.paymentFrequencyOption === "other"
                        }
                        onChange={validation.handleChange}
                        className="mb-3"
                      />
                      {validation.values.paymentFrequencyOption === "other" && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Payment Frequency Option - Other *
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="otherPaymentFrequency"
                            name="otherPaymentFrequency"
                            value={validation.values.otherPaymentFrequency}
                            onChange={validation.handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {validation.errors.otherPaymentFrequency}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}

                      <Form.Control.Feedback type="invalid">
                        {validation.errors.paymentFrequencyOption &&
                          validation.touched.paymentFrequencyOption &&
                          validation.errors.paymentFrequencyOption}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                </Row>
              )}

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Right to Subcontracting Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="Right to Subcontracting"
                      type="radio"
                      name="rightToSubcontract"
                      value="Right to Subcontracting"
                      checked={
                        validation.values.rightToSubcontract ===
                        "Right to Subcontracting"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="No Right to Subcontracting"
                      type="radio"
                      name="rightToSubcontract"
                      value="No Right to Subcontracting"
                      checked={
                        validation.values.rightToSubcontract ===
                        "No Right to Subcontracting"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Assignment Right Options *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="Subcontractor shall have the right to assign any rights under this Agreement or any part of the Services issued herein."
                      type="radio"
                      name="assignmentRight"
                      value="subcontractorCanAssign"
                      checked={
                        validation.values.assignmentRight ===
                        "subcontractorCanAssign"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Check
                      label="Subcontractor shall not have the right to assign any rights under this Agreement or any part of the Services issued herein."
                      type="radio"
                      name="assignmentRight"
                      value="subcontractorCannotAssign"
                      checked={
                        validation.values.assignmentRight ===
                        "subcontractorCannotAssign"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />

                    <Form.Control.Feedback type="invalid">
                      {validation.errors.assignmentRight &&
                        validation.touched.assignmentRight &&
                        validation.errors.assignmentRight}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={6}>
                  <h5>Subcontractor’s Insurance *</h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Carries commercial general liability insurance"
                    name="subContractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="yes"
                    checked={validation.values.subContractorInsurance === "yes"}
                    onChange={validation.handleChange}
                  />
                  {validation.values.subContractorInsurance === "yes" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Insurance Provider Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="subInsuranceProviderName"
                        value={validation.values.subInsuranceProviderName}
                        onChange={validation.handleChange}
                      />
                      {validation.errors.subInsuranceProviderName &&
                        validation.touched.subInsuranceProviderName && (
                          <div className="text-danger">
                            {validation.errors.subInsuranceProviderName}
                          </div>
                        )}
                    </Form.Group>
                  )}
                  <Form.Check
                    label="The Contractor does not carry commercial general liability insurance"
                    name="subContractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="no"
                    checked={validation.values.subContractorInsurance === "no"}
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="The Contractor is self-insured"
                    name="subContractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="selfInsured"
                    checked={
                      validation.values.subContractorInsurance === "selfInsured"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.errors.subContractorInsurance &&
                    validation.touched.subContractorInsurance && (
                      <div className="text-danger">
                        {validation.errors.subContractorInsurance}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>
                    Options for Subcontractor’s Worker's Compensation Insurance
                    *
                  </h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Subcontractor’s has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations"
                    name="subWorkersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="noEmployees"
                    checked={
                      validation.values.subWorkersCompensationInsurance ===
                      "noEmployees"
                    }
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="Subcontractor’s carries Workers’ Compensation Insurance for all employees"
                    name="subWorkersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="hasInsurance"
                    checked={
                      validation.values.subWorkersCompensationInsurance ===
                      "hasInsurance"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.values.subWorkersCompensationInsurance ===
                    "hasInsurance" && (
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Subcontractor’s Worker's Compensation Insurance
                            Limit *
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="subWorkersCompensationInsuranceLimit"
                            value={
                              validation.values
                                .subWorkersCompensationInsuranceLimit
                            }
                            onChange={validation.handleChange}
                          />
                          {validation.errors
                            .subWorkersCompensationInsuranceLimit &&
                            validation.touched
                              .subWorkersCompensationInsuranceLimit && (
                              <div className="text-danger">
                                {
                                  validation.errors
                                    .subWorkersCompensationInsuranceLimit
                                }
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {validation.errors.subWorkersCompensationInsurance &&
                    validation.touched.subWorkersCompensationInsurance && (
                      <div className="text-danger">
                        {validation.errors.subWorkersCompensationInsurance}
                      </div>
                    )}
                </Col>
              </Row>
              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={10}>
                  <h5>Additional Provisions</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Additional Provisions</Form.Label>
                    <Form.Control
                      as="textarea"
                      id="additionalProvision"
                      name="additionalProvision"
                      value={validation.values.additionalProvision || ""}
                      onChange={validation.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-3 rounded">
                <Col>
                  <h5>Document name *</h5>
                  <Row>
                    <p>
                      Please indicate what you would like the contract’s PDF
                      file name to be. This name will be shared with all signing
                      parties *
                    </p>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          id="documentName"
                          name="documentName"
                          value={validation.values.documentName || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.errors.documentName && (
                          <div className="text-danger">
                            {validation.errors.documentName}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="p-4 rounded">
                <Col className="d-flex justify-content-center">
                  <Button type="submit" variant="primary">
                    Create Contract
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddSubContract;
