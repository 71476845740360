import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
  Table,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import CreatableSelect from "react-select/creatable";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  createUserProfile,
  deleteDocument,
  geCompanyData,
  handleStripePackage,
  getSubscribedUser,
  getCards,
  getAllCategories,
  getSubCategories,
  checkVideoPaymentStatus,
  handleVideoPackage,
  getRecentWork,
} from "../../../../slices/thunk";
import { createSelector } from "reselect";
import RecentWorkList from "./recent-work/RecentWork";
interface PersonalDataProps {
  data: any;
}
const AdminPersonal: React.FC<PersonalDataProps> = ({ data }) => {
  const dispatch: any = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  console.log(id, "7896");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [userFormattedPhoneNumber, setUserFormattedPhoneNumber] = useState("");
  const [cardData, setCardData] = useState([]);
  const options = [
    { value: "Plumber", label: "Plumber" },
    { value: "Electrician", label: "Electrician" },
    { value: "Architecture", label: "Architecture" },
    { value: "Interior Designer", label: "Interior Designer" },
  ];
  const profileOptions = [
    { value: "Plumber", label: "Plumber" },
    { value: "Electrician", label: "Electrician" },
    { value: "Carpenter", label: "Carpenter" },
    { value: "Architecturer", label: "Architecture" },
    { value: "General contractor", label: "General contractor" },
    { value: "Deck installer", label: "Deck installer" },
    { value: "Window and Door", label: "Window and Door" },
    { value: "Siding Contractor", label: "Siding Contractor" },
    { value: "Insulation Contractor", label: "Insulation Contractor" },
    { value: "Home inspector,", label: "Home inspector," },
    { value: "Roofing Contractors", label: "Roofing Contractors" },
    { value: "Irrigation Contractor", label: "Irrigation Contractor" },
    { value: "Exterminator Contractor", label: "Exterminator Contractor" },
    { value: "Gutter Company", label: "Gutter Company" },
    { value: "Blacktop, Paver", label: "Blacktop-Paver" },
    { value: "Landscaper", label: "Landscaper" },
    { value: "Landscape Architect", label: "Landscape Architect" },
    { value: "Septic Company", label: "Septic Company" },
    { value: "Alarm Company,", label: "Alarm Company," },
    { value: "Tile and Masonary Company", label: "Tile and Masonary Company" },
    {
      value: "HVAC Heating and Cooling Contractor",
      label: "HVAC Heating and Cooling Contractor",
    },
    { value: "Painting Contractor", label: "Painting Contractor" },
    { value: "Sheet Rock", label: "Sheet Rock" },
  ];
  const workAvailable = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
  ];

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  // Function to handle input change for company phone number
  const handleCompanyPhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    setFormattedPhoneNumber(formatPhoneNumber(input));
    companyValidation.setFieldValue("companyPhoneNumber", input);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    setUserFormattedPhoneNumber(formatPhoneNumber(input));
    validation.setFieldValue("phoneNumber", input);
  };

  useEffect(() => {
    if (data) {
      console.log("mkfmkfw");
      dispatch(getCards(data.id)).then((resp: any) => {
        setCardData(resp);
      });
    }
  }, [data]);

  let parsedArray = [];
  if (data) {
    const stringArray = data && data.ua_skills ? data.ua_skills : null;
    parsedArray = JSON.parse(stringArray);
  }
  let parsedProflie = [];
  if (data) {
    const stringArray = data && data.ua_profile ? data.ua_profile : null;
    parsedProflie = JSON.parse(stringArray);
  }

  let parsedAvailArray = [];
  if (data) {
    const stringArray =
      data && data.ua_work_availbility ? data.ua_work_availbility : null;
    parsedAvailArray = JSON.parse(stringArray);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (data && data.user_first_name) || "",
      lastName: (data && data.user_last_name) || "",
      phoneNumber: (data && data.user_phone) || "",
      email: (data && data.user_email) || "",
      birthDate: (data && data.ua_birth_date) || "",
      joiningDate: (data && data.ua_joining_date) || "",
      profile: parsedProflie || [],
      skills: parsedArray || [],
      workAvailability: parsedAvailArray || [],
      experience: (data && data.ua_exp) || "",
      workHours: (data && data.ua_work_hours) || "",
      cityInput: (data && data.ua_city) || "",
      zipcodeInput: (data && data.ua_zip_code) || "",
      countryInput: (data && data.ua_country) || "",
      descpription: (data && data.ua_description) || "",
      address: (data && data.ua_address) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your email"),
      lastName: Yup.string().required("Please enter your email"),
      phoneNumber: Yup.string().required("Please enter your email"),
      email: Yup.string().required("Please enter your email"),
      //birthDate: Yup.string().required("Please enter your email"),
      //joiningDate: Yup.string().required("Please enter your email"),
      //skills: Yup.string().required("Please enter your email"),
      // workAvailability: Yup.string().required("Please enter your email"),
      experience: Yup.string().required("Please enter your email"),
      workHours: Yup.string().required("Please enter your email"),
      cityInput: Yup.string().required("Please enter your email"),
      zipcodeInput: Yup.string().required("Please enter your email"),
      countryInput: Yup.string().required("Please enter your email"),
      descpription: Yup.string().required("Please enter your description"),
      address: Yup.string().required("Please enter your address"),
    }),
    onSubmit: (values) => {
      //createdataata(values);
    },
  });

  const companyValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: (data && data.cc_company_name) || "",
      companyRegisterdNumber: (data && data.cc_company_registered_number) || "",
      companyPhoneNumber: (data && data.cc_phone_number) || "",
      companyEmail: (data && data.cc_company_email) || "",
      companyRegisterdDate: (data && data.cc_registration_date) || "",
      companyCity: (data && data.cc_city) || "",
      companyAddress: (data && data.user_last_name) || "",
      companyState: (data && data.cc_state) || "",
      companyZip: (data && data.cc_zip_code) || "",
      companyCountry: (data && data.cc_country) || "",
      companyInsuranceDocument:
        (data && data.cc_insurance_documentation_url) || "",
      companyLicenceDocument: (data && data.cc_licence_documentation_url) || "",
      companyContractorDocument:
        (data && data.cc_contractor_documentation_url) || "",
      //companyLogo: (companyD && userD.user_last_name) || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      //createCompanyData(values);
    },
  });

  useEffect(() => {
    if (companyValidation.values.companyPhoneNumber) {
      const formattedNumber = formatPhoneNumber(
        companyValidation.values.companyPhoneNumber
      );
      setFormattedPhoneNumber(formattedNumber);
    }
  }, [companyValidation.values.companyPhoneNumber]);

  useEffect(() => {
    if (validation.values.phoneNumber) {
      const userFormattedPhoneNumber = formatPhoneNumber(
        validation.values.phoneNumber
      );
      setUserFormattedPhoneNumber(userFormattedPhoneNumber);
    }
  }, [validation.values.phoneNumber]);

  return (
    <React.Fragment>
      <Col xl={9}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
            <div className="col-md order-1">
              <Nav
                variant="pills"
                className="arrow-navtabs nav-secondary gap-2 flex-grow-1"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first" href="#personalDetails">
                    Personal Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" href="#companyDetails">
                    Company Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third" href="#financial">
                    Financials
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth" href="#recentWork">
                    Recent Work
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="fourth" href="#changePassword">
                    Change Password
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
          </Row>
          <Tab.Content>
            <Tab.Pane eventKey="first" id="personalDetails">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">Personal Details</h6>
                </Card.Header>
                <Card.Body>
                  <Form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="firstName"
                            name="firstName"
                            disabled
                            placeholder="Enter your firstname"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            isInvalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <Form.Control.Feedback type="invalid">
                            {/* {validation.errors.firstName} */}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="lastName">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            id="lastName"
                            name="lastName"
                            disabled
                            placeholder="Enter your last name"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            isInvalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.lastName === "string"
                              ? validation.errors.lastName
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="phoneNumber">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            required
                            onChange={handlePhoneNumberChange}
                            onBlur={validation.handleBlur}
                            value={userFormattedPhoneNumber}
                            disabled
                            isInvalid={
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.phoneNumber === "string"
                              ? validation.errors.phoneNumber
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="email">Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            disabled
                            placeholder="Enter your email"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            isInvalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.email === "string"
                              ? validation.errors.email
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="birthDate">
                            Date of Birth
                          </Form.Label>
                          <Flatpickr
                            id="birthDate"
                            name="birthDate"
                            className="form-control"
                            options={{ dateFormat: "d M, Y" }}
                            disabled
                            placeholder="Select date"
                            value={validation.values.birthDate}
                            onChange={(date: any) =>
                              validation.setFieldValue("birthDate", date[0])
                            }
                          />
                          {validation.errors.birthDate &&
                            validation.touched.birthDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.birthDate === "string"
                                  ? validation.errors.birthDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="joiningDate">
                            Joining Date
                          </Form.Label>
                          <Flatpickr
                            id="joiningDate"
                            name="joiningDate"
                            className="form-control"
                            options={{ dateFormat: "d M, Y" }}
                            disabled
                            placeholder="Select date"
                            value={validation.values.joiningDate}
                            onChange={(date: any) =>
                              validation.setFieldValue("joiningDate", date[0])
                            }
                          />
                          {validation.errors.joiningDate &&
                            validation.touched.joiningDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.joiningDate ===
                                "string"
                                  ? validation.errors.joiningDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="profile" className="text-muted">
                            Profession
                          </Form.Label>
                          <Select
                            id="profile"
                            name="profile"
                            isDisabled
                            options={profileOptions}
                            value={validation.values.profile}
                            onChange={(selectedProfile: any) => {
                              // Update the field value with the selected profile
                              validation.setFieldValue(
                                "profile",
                                selectedProfile
                              );
                            }}
                          />
                          {validation.errors.profile &&
                            validation.touched.profile && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.profile === "string"
                                  ? validation.errors.profile
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="skills" className="text-muted">
                            Skills
                          </Form.Label>
                          <CreatableSelect
                            id="skills"
                            name="skills"
                            isMulti
                            isDisabled
                            value={validation.values.skills}
                            onChange={(selectedSkills: any) => {
                              validation.setFieldValue(
                                "skills",
                                selectedSkills
                              );
                            }}
                          />
                          {validation.errors.skills &&
                            validation.touched.skills && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.skills === "string"
                                  ? validation.errors.skills
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="workAvailability">
                              Work Availability
                            </Form.Label>
                            <Select
                              id="workAvailability"
                              name="workAvailability"
                              isDisabled
                              options={workAvailable}
                              value={validation.values.workAvailability}
                              onChange={(selectedWorkAvail: any) => {
                                // Update the field value with the selected profile
                                validation.setFieldValue(
                                  "workAvailability",
                                  selectedWorkAvail
                                );
                              }}
                            />
                            {validation.errors.workAvailability &&
                              validation.touched.workAvailability && (
                                <Form.Control.Feedback type="invalid">
                                  {typeof validation.errors.workAvailability ===
                                  "string"
                                    ? validation.errors.workAvailability // Changed from 'profile' to 'workAvailability'
                                    : ""}
                                </Form.Control.Feedback>
                              )}
                          </Form.Group>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="experience">
                            Experience
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="experience"
                            name="experience"
                            required
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.experience || ""}
                            isInvalid={
                              validation.touched.experience &&
                              validation.errors.experience
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.experience &&
                        validation.errors.experience ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.experience === "string"
                              ? validation.errors.experience
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="workHours">
                            Work Hours
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            id="workHours"
                            name="workHours"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.workHours || ""}
                            isInvalid={
                              validation.touched.workHours &&
                              validation.errors.workHours
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.workHours &&
                        validation.errors.workHours ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.workHours === "string"
                              ? validation.errors.workHours
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="cityInput">City</Form.Label>
                          <Form.Control
                            type="text"
                            id="cityInput"
                            name="cityInput"
                            disabled
                            placeholder="City"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cityInput || ""}
                            isInvalid={
                              validation.touched.cityInput &&
                              validation.errors.cityInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.cityInput &&
                        validation.errors.cityInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.cityInput === "string"
                              ? validation.errors.cityInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="countryInput">
                            Country
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="countryInput"
                            name="countryInput"
                            disabled
                            placeholder="Country"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.countryInput || ""}
                            isInvalid={
                              validation.touched.countryInput &&
                              validation.errors.countryInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.countryInput &&
                        validation.errors.countryInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.countryInput === "string"
                              ? validation.errors.countryInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="zipcodeInput">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            minLength={5}
                            maxLength={6}
                            id="zipcodeInput"
                            name="zipcodeInput"
                            disabled
                            placeholder="Enter zipcode"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipcodeInput || ""}
                            isInvalid={
                              validation.touched.zipcodeInput &&
                              validation.errors.zipcodeInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.zipcodeInput &&
                        validation.errors.zipcodeInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.zipcodeInput === "string"
                              ? validation.errors.zipcodeInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="address">Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            id="address"
                            name="address"
                            disabled
                            placeholder="Enter your address"
                            value={validation.values.address || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.errors.address &&
                            validation.touched.address && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.address === "string"
                                  ? validation.errors.address
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="descpription">
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            id="descpription"
                            name="descpription"
                            disabled
                            placeholder="Enter your description"
                            value={validation.values.descpription || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.descpription &&
                              validation.errors.descpription
                                ? true
                                : false
                            }
                          />
                          {validation.errors.descpription &&
                            validation.touched.descpription && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.descpription ===
                                "string"
                                  ? validation.errors.descpription
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      {/* <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                          <Button className="btn btn-subtle-danger">
                            Cancel
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second" id="companyDetails">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">
                    Contractor Company Details
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      companyValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="companyName">
                            Company Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="companyName"
                            name="companyName"
                            disabled
                            placeholder="Enter your company name"
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            value={companyValidation.values.companyName || ""}
                            isInvalid={
                              companyValidation.touched.companyName &&
                              companyValidation.errors.companyName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {companyValidation.touched.companyName &&
                        companyValidation.errors.companyName ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof companyValidation.errors.companyName ===
                            "string"
                              ? companyValidation.errors.companyName
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="companyRegisterdNumber">
                            Company Registered Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="companyRegisterdNumber"
                            name="companyRegisterdNumber"
                            disabled
                            placeholder="Enter your company registerd number"
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            value={
                              companyValidation.values.companyRegisterdNumber ||
                              ""
                            }
                            isInvalid={
                              companyValidation.touched
                                .companyRegisterdNumber &&
                              companyValidation.errors.companyRegisterdNumber
                                ? true
                                : false
                            }
                          />
                        </div>
                        {companyValidation.touched.companyRegisterdNumber &&
                        companyValidation.errors.companyRegisterdNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof companyValidation.errors
                              .companyRegisterdNumber === "string"
                              ? companyValidation.errors.companyRegisterdNumber
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="companyPhoneNumber">
                            Company Phone Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="companyPhoneNumber"
                            name="companyPhoneNumber"
                            disabled
                            placeholder="Enter your company phone number"
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            value={formattedPhoneNumber}
                            isInvalid={
                              companyValidation.touched.companyPhoneNumber &&
                              companyValidation.errors.companyPhoneNumber
                                ? true
                                : false
                            }
                          />
                        </div>
                        {companyValidation.touched.companyPhoneNumber &&
                        companyValidation.errors.companyPhoneNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof companyValidation.errors
                              .companyPhoneNumber === "string"
                              ? companyValidation.errors.companyPhoneNumber
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="companyEmail">
                            Company Email Address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            id="companyEmail"
                            name="companyEmail"
                            disabled
                            placeholder="Enter your company email"
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            value={companyValidation.values.companyEmail || ""}
                            isInvalid={
                              companyValidation.touched.companyEmail &&
                              companyValidation.errors.companyEmail
                                ? true
                                : false
                            }
                          />
                        </div>
                        {companyValidation.touched.companyEmail &&
                        companyValidation.errors.companyEmail ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof companyValidation.errors.companyEmail ===
                            "string"
                              ? companyValidation.errors.companyEmail
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Form.Label htmlFor="JoiningdatInput">
                            Company Registered Date
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            id="companyRegisterdDate"
                            name="companyRegisterdDate"
                            options={{ dateFormat: "d M, Y" }}
                            disabled
                            placeholder="Select date"
                            value={
                              companyValidation.values.companyRegisterdDate
                            }
                            onChange={(date: any) =>
                              companyValidation.setFieldValue(
                                "birthDate",
                                date[0]
                              )
                            }
                          />
                          {companyValidation.errors.companyRegisterdDate &&
                            companyValidation.touched.companyRegisterdDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyRegisterdDate === "string"
                                  ? companyValidation.errors
                                      .companyRegisterdDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-6">
                          <Form.Label htmlFor="companyAddress">
                            Company Address
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            id="companyAddress"
                            name="companyAddress"
                            disabled
                            placeholder="Enter your address"
                            value={
                              companyValidation.values.companyAddress || ""
                            }
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            isInvalid={
                              companyValidation.touched.companyAddress &&
                              companyValidation.errors.companyAddress
                                ? true
                                : false
                            }
                          />
                          {companyValidation.errors.companyAddress &&
                            companyValidation.touched.companyAddress && (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyAddress === "string"
                                  ? companyValidation.errors.companyAddress
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={4} className="mt-4">
                        <div className="mb-6">
                          <Form.Label htmlFor="companyCity">City</Form.Label>
                          <Form.Control
                            type="text"
                            id="companyCity"
                            name="companyCity"
                            disabled
                            placeholder="City"
                            value={companyValidation.values.companyCity || ""}
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            isInvalid={
                              companyValidation.touched.companyCity &&
                              companyValidation.errors.companyCity
                                ? true
                                : false
                            }
                          />
                          {companyValidation.errors.companyCity &&
                            companyValidation.touched.companyCity && (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors.companyCity ===
                                "string"
                                  ? companyValidation.errors.companyCity
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={4} className="mt-4">
                        <div className="mb-6">
                          <Form.Label htmlFor="companyCountry">
                            Country
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="companyCountry"
                            name="companyCountry"
                            disabled
                            placeholder="Country"
                            value={
                              companyValidation.values.companyCountry || ""
                            }
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            isInvalid={
                              companyValidation.touched.companyCountry &&
                              companyValidation.errors.companyCountry
                                ? true
                                : false
                            }
                          />
                          {companyValidation.errors.companyCountry &&
                            companyValidation.touched.companyCountry && (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyCountry === "string"
                                  ? companyValidation.errors.companyCountry
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={4} className="mt-4">
                        <div className="mb-6">
                          <Form.Label htmlFor="companyZip">Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            minLength={5}
                            maxLength={6}
                            id="companyZip"
                            name="companyZip"
                            disabled
                            placeholder="Enter zipcode"
                            value={companyValidation.values.companyZip || ""}
                            onChange={companyValidation.handleChange}
                            onBlur={companyValidation.handleBlur}
                            isInvalid={
                              companyValidation.touched.companyZip &&
                              companyValidation.errors.companyZip
                                ? true
                                : false
                            }
                          />
                          {companyValidation.errors.companyZip &&
                            companyValidation.touched.companyZip && (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors.companyZip ===
                                "string"
                                  ? companyValidation.errors.companyZip
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={6} className="mt-4">
                        <Form.Label>Insurance documentation</Form.Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data && data.cc_insurance_documentation_url ? (
                            <>
                              <div>
                                <img
                                  src={
                                    data && data.cc_insurance_documentation_url
                                  }
                                  height={200}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div>
                                <a
                                  href={
                                    data && data.cc_insurance_documentation_url
                                  }
                                  download
                                >
                                  <Button
                                    variant="primary"
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                  >
                                    Download
                                  </Button>
                                </a>
                              </div>
                            </>
                          ) : (
                            "No Document"
                          )}
                        </div>
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label>Company Licence documentation</Form.Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data && data.cc_licence_documentation_url ? (
                            <>
                              <div>
                                <img
                                  src={
                                    data && data.cc_licence_documentation_url
                                  }
                                  height={200}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div>
                                <a
                                  href={
                                    data && data.cc_licence_documentation_url
                                  }
                                  download
                                >
                                  <Button
                                    variant="primary"
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                  >
                                    Download
                                  </Button>
                                </a>
                              </div>
                            </>
                          ) : (
                            "No Document"
                          )}
                        </div>
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label>Contractor Licence</Form.Label>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data && data.cc_contractor_documentation_url ? (
                            <>
                              <div>
                                <img
                                  src={
                                    data && data.cc_contractor_documentation_url
                                  }
                                  height={200}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div>
                                <a
                                  href={
                                    data && data.cc_contractor_documentation_url
                                  }
                                  download
                                >
                                  <Button
                                    variant="primary"
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                  >
                                    Download
                                  </Button>
                                </a>
                              </div>
                            </>
                          ) : (
                            "No Document"
                          )}
                        </div>
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label>Company Logo</Form.Label>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data && data.cc_logo_url ? (
                            <>
                              <div>
                                <img
                                  src={data && data.cc_logo_url}
                                  height={200}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div>
                                <a href={data && data.cc_logo_url} download>
                                  <Button
                                    variant="primary"
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                  >
                                    Download
                                  </Button>
                                </a>
                              </div>
                            </>
                          ) : (
                            "No Document"
                          )}
                        </div>
                      </Col>

                      <Col lg={12} className="mt-4">
                        <Form.Label>Corporate Video</Form.Label>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {data && data.cc_video_url ? (
                            <>
                              <div>
                                <video
                                  src={data && data.cc_video_url}
                                  height={200}
                                  style={{ width: "100%" }}
                                  controls
                                  preload="auto"
                                >
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                              <div>
                                <a href={data && data.cc_video_url} download>
                                  <Button
                                    variant="primary"
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                  >
                                    Download
                                  </Button>
                                </a>
                              </div>
                            </>
                          ) : (
                            "No Document"
                          )}
                        </div>
                      </Col>

                      {/* <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <Button type="submit" variant="primary">
                            Approve
                          </Button>
                          <Button className="btn btn-subtle-danger">
                            Cancel
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>

          <Tab.Content>
            <Tab.Pane eventKey="third" id="education">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">Financials</h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {data && data.cp_is_verified ? (
                      <Table className="table-striped table-nowrap align-middle mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Card Type</th>
                            <th>Last 4 Digits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardData &&
                            cardData.length &&
                            cardData.map((item: any) => (
                              <tr>
                                <td>{item.billing_details.name}</td>
                                <td>{item.card.brand}</td>
                                <td>{item.card.last4}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>
                        Payment Not Verified
                      </h4>
                    )}
                  </Row>
                  {/* <form>
                    <div id="newlink">
                      <div id="1">
                        <Row>
                          
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Account Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="degreeName"
                                disabled placeholder="Account name"
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Account Type
                              </Form.Label>
                              <Form.Select
                                data-choices
                                name="passedYear"
                                id="passedyear"
                                defaultValue={"Choice 17"}
                              >
                                <option value="">Select Account Type</option>
                                <option value="Choice 1">Saving</option>
                                <option value="Choice 2">Current</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Bank Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="degreeName"
                                disabled placeholder="Bank name"
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="universityName">
                                Account Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="universityName"
                                disabled placeholder="Account Number"
                              />
                            </div>
                          </Col>

                          
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="universityName">
                                Swift Code
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="universityName"
                                disabled placeholder="Swift Code"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <h4 style={{ textAlign: "center" }}>OR</h4>

                    <div id="newlink">
                      <div id="1">
                      
                      </div>
                    </div>
                    <Col lg={12}>
                      <div className="hstack gap-2">
                        <Button type="submit" className="btn btn-secondary">
                          Update
                        </Button>
                      </div>
                    </Col>
                  </form> */}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          {/*Fifth Recent Work */}
          <Tab.Content>
            <Tab.Pane eventKey="fifth" id="recentWork">
              <RecentWorkList recentWork={data} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </React.Fragment>
  );
};

export default AdminPersonal;
