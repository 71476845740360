import React, { useState, useEffect } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import small7 from "assets/images/small/img-7.jpg";
import avatar1 from "assets/images/users/avatar-1.jpg";
import { Link } from 'react-router-dom';
import { updateUserSocialProfile } from "../../../slices/thunk";
interface PersonalDataProps { 
  userData: any;
  data: any;
  onFileChange: (type: string, filePath: string) => void;
  onSocialChange: (social: string) => void;
  profileCompleted: any
  createUserData: (values: any) => void;
  isLoading:any
}

const Right: React.FC<PersonalDataProps> = ({ userData, data, onFileChange, onSocialChange, profileCompleted, createUserData,isLoading }) => {
  const initialSocialData = data && data.ua_social_url ? JSON.parse(data.ua_social_url) : {
    linkedIn: '',
    fb: '',
    instagram: ''
  };

  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [profileImagePath, setProfileImagePath] = useState<string>("");
  const [inputValues, setInputValues] = useState(initialSocialData);

  useEffect(() => {
    setInputValues(initialSocialData);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const updatedValues = {
      ...inputValues,
      [fieldName]: event.target.value
    };
    setInputValues(updatedValues);
    onSocialChange(JSON.stringify(updatedValues));
  };

  const handleCoverImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file:any = event.target.files && event.target.files[0];
    if (file) {
      const filepath = URL.createObjectURL(file);
      setCoverImagePath(filepath);
      onFileChange('cover', file);
    }
  };

  const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file:any = event.target.files && event.target.files[0];
    if (file) {
      const filepath = URL.createObjectURL(file);
      setProfileImagePath(filepath);
      onFileChange('profile', file);
    }
  };

  return (
    <React.Fragment>
      <Col xxl={3}>
        <Card className="overflow-hidden">
          <div>
            <img 
              src={data?.ua_cover_pic || coverImagePath || small7} 
              alt="" 
              className="card-img-top profile-wid-img object-fit-cover" 
              style={{ height: "200px" }} 
            />
            <div>
              <input 
                id="profile-foreground-img-file-input" 
                type="file" 
                className="profile-foreground-img-file-input d-none" 
                onChange={handleCoverImageChange} 
              />
              <label htmlFor="profile-foreground-img-file-input" className="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3">
                <i className="ri-image-edit-line align-bottom me-1"></i> Edit Cover Images
              </label>
            </div>
          </div>
          <Card.Body className="pt-0 mt-n5">
            <div className="text-center">
              <div className="profile-user position-relative d-inline-block mx-auto">
                <img 
                  src={data?.ua_profile_pic || profileImagePath || avatar1} 
                  alt="" 
                  className="avatar-lg rounded-circle object-fit-cover border-0 img-thumbnail user-profile-image" 
                />
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit position-absolute end-0 bottom-0">
                  <input 
                    id="profile-img-file-input" 
                    type="file" 
                    className="profile-img-file-input d-none" 
                    onChange={handleProfileImageChange} 
                  />
                  <label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="bi bi-camera"></i>
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-3">
                <h5>{userData?.user_first_name} {userData?.user_last_name} <i className="bi bi-patch-check-fill align-baseline text-info ms-1"></i></h5>
                <p className="text-muted">{userData?.user_type}</p>
              </div>
            </div>
          </Card.Body>
          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4 pb-2">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Complete Your Profile</h5>
              </div>
            </div>
            <div className="progress animated-progress custom-progress progress-label">
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${profileCompleted}%` }}
                aria-valuenow={30} aria-valuemin={0} aria-valuemax={100}>
                <div className="label">{ profileCompleted}%</div>
              </div>
            </div>
          </Card.Body>
          <Card.Body className="border-top">
            <div className="d-flex align-items-center mb-4">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Portfolio</h5>
              </div>
            </div>
            <div>
              <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                    <i className="bi bi-facebook"></i>
                  </span>
                </div>
                <input 
                  type="text" 
                  className="form-control" 
                  id="websiteInput" 
                  placeholder="www.example.com"
                  value={inputValues.fb}
                  onChange={(e) => handleChange(e, 'fb')}
                />
              </div>
              <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-success-subtle text-success">
                    <i className="bi bi-linkedin"></i>
                  </span>
                </div>
                <input 
                  type="text" 
                  className="form-control" 
                  id="dribbleName" 
                  placeholder="Username"
                  value={inputValues.linkedIn}
                  onChange={(e) => handleChange(e, 'linkedIn')}
                />
              </div>
              <div className="d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                  <span className="avatar-title rounded-circle bg-danger-subtle text-danger">
                    <i className="bi bi-instagram"></i>
                  </span>
                </div>
                <input 
                  type="text" 
                  className="form-control" 
                  id="instagramName" 
                  placeholder="Username"
                  value={inputValues.instagram}
                  onChange={(e) => handleChange(e, 'instagram')}
                />
              </div>
            </div>
            <Button onClick={createUserData} variant="primary" className='mt-4 w-100' type='button'>Submit</Button>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Right;
