//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import axios from "axios";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../helpers/fakebackend_helper";
import { jwtDecode } from "jwt-decode";
import { contractorCompanyStatus, apiError, contractorCompanySuccess,createCompanySuccess, deleteDocumentSuccess } from './reducer';
import { values } from "lodash";
import userProfile from "pages/Authentication/user-profile";

import apiUrl from "slices/apiEndpoint";

export const getCompanyStatus = () => async (dispatch: any) => {
     const token = localStorage.getItem('authUser');
    if (!token) {
      console.error('Error: Authentication token not found');
      return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    try {
      const conEndPoint = `${apiUrl}/profile/check-user/${getDecodeId()}`;
  
       const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });
  
      if (resp) {
        ////console.log(resp.data.status, "sss")
        dispatch(contractorCompanyStatus(resp.data.status))
        return resp.data.status
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(apiError('Error fetching contractors: Invalid response format'))
      }
    } catch (error) {
      dispatch(apiError(error))
      // Handle the error, e.g., set an error state
    }
}

export const geCompanyData = () => async (dispatch: any) => {
     const token = localStorage.getItem('authUser');
    if (!token) {
      console.error('Error: Authentication token not found');
      return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    try {
      const conEndPoint = `${apiUrl}/company/get-company/${getDecodeId()}`;
  
       const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });
  
      if (resp) {
        dispatch(contractorCompanySuccess(resp.data[0]))
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(apiError('Error fetching contractors: Invalid response format'))
      }
    } catch (error) {
      dispatch(apiError(error))
      // Handle the error, e.g., set an error state
    }
}
  


export const createCompany = (userProfile: any, licence:any, contractorLicence:any, insurance:any, videoUrl:any, logo:any) => async (dispatch: any) => {
    const token = localStorage.getItem('authUser');
    if (!token) {
        console.error('Error: Authentication token not found');
        return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    
    try {
        const conEndPoint = `${apiUrl}/company/create-company`;
        
      const resp = await axios.post(conEndPoint, {
    userId: getDecodeId(),
    companyName:userProfile.companyName,
    companyRegisteredNumber: userProfile.companyRegisterdNumber,
    phoneNumber: userProfile.companyPhoneNumber,
    companyEmail: userProfile.companyEmail,
    registrationDate: userProfile.companyRegisterdDate,
    address: userProfile.companyAddress,
    city: userProfile.companyCity,
    state: userProfile.companyState,
    country: userProfile.companyCountry,
    zipCode: userProfile.companyZip,
    insuranceDocument: insurance,
    licenceDocument: licence,
        contractorDocument: contractorLicence,
          contractorVideoUrl: videoUrl,
    logoUrl:logo
            
        }, {
            headers: {
                Authorization: formattedToken,
            }
        });
        
        if (resp) {
            //console.log(resp, "resp")
            dispatch(createCompanySuccess(resp.data))
            // Handle the response data as needed
        } else {
            //console.log("dbddjjsj")
            dispatch(apiError('Error fetching contractors: Invalid response format'))
        }
    } catch (error) {
        dispatch(apiError(error))
        // Handle the error, e.g., set an error state
    }
}

export const updateCompany = (userProfile: any, licence:any, contractorLicence:any, insurance:any, videoUrl:any, logo:any) => async (dispatch: any) => {
  const token = localStorage.getItem('authUser');
  console.log(userProfile, "userProfile in update again")
    if (!token) {
        console.error('Error: Authentication token not found');
        return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    
    try {
        const conEndPoint = `${apiUrl}/company/update-company/${getDecodeId()}`;
        
      const resp = await axios.put(conEndPoint, {
            
            companyName:userProfile.companyName,
            companyRegisteredNumber: userProfile.companyRegisterdNumber,
            phoneNumber: userProfile.companyPhoneNumber,
            companyEmail: userProfile.companyEmail,
            registrationDate: userProfile.companyRegisterdDate,
            address: userProfile.companyAddress,
            city: userProfile.companyCity,
            state: userProfile.companyState,
            country: userProfile.companyCountry,
            zipCode: userProfile.companyZip,
            insuranceDocument: insurance,
            licenceDocument: licence,
            contractorDocument: contractorLicence,
            contractorVideoUrl: videoUrl,
            logoUrl:logo
            
        }, {
            headers: {
                Authorization: formattedToken,
            }
        });
        
        if (resp) {
            //console.log(resp, "resp")
            dispatch(createCompanySuccess(resp.data))
            // Handle the response data as needed
        } else {
            //console.log("dbddjjsj")
            dispatch(apiError('Error fetching contractors: Invalid response format'))
        }
    } catch (error) {
        dispatch(apiError(error))
        // Handle the error, e.g., set an error state
    }
}


export const deleteDocument = (documentType:any) => async (dispatch: any) => {
  const token = localStorage.getItem('authUser');
  //console.log(userProfile, "userProfile")
    if (!token) {
        console.error('Error: Authentication token not found');
        return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    
    try {
        const conEndPoint = `${apiUrl}/company/delete-document`;
        
      const resp = await axios.patch(conEndPoint, {
            
            userId:getDecodeId(),
            documentType:documentType
        }, {
            headers: {
                Authorization: formattedToken,
            }
        });
        
        if (resp) {
            //console.log(resp, "resp")
            dispatch(deleteDocumentSuccess("deleted Succesfully"))
            // Handle the response data as needed
        } else {
            //console.log("dbddjjsj")
            dispatch(apiError('Error fetching contractors: Invalid response format'))
        }
    } catch (error) {
        dispatch(apiError(error))
        // Handle the error, e.g., set an error state
    }
}


export const getUserData = () => async (dispatch: any) => {
     const token = localStorage.getItem('authUser');
    if (!token) {
      console.error('Error: Authentication token not found');
      return;
    }
    const newStr = token.replace(/^"|"$/g, '');
    const formattedToken = 'Bearer ' + newStr;
    try {
      const conEndPoint = `${apiUrl}/user/${getDecodeId()}`;
  
       const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });
  
      if (resp) {
        //console.log(resp, "resp")
        ////console.log(resp.data.status, "sss")
        dispatch(createCompanySuccess(resp.data))
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(apiError('Error fetching contractors: Invalid response format'))
      }
    } catch (error) {
      dispatch(apiError(error))
      // Handle the error, e.g., set an error state
    }
}


  function getDecodeId() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userId
}