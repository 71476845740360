import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Delaware from "./Delaware";
import Florida from "./Florida";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import {
  createHicContract,
  getSingleProjectById,
} from "../../../../slices/thunk";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import Massuchusetts from "./Massuchusetts";
import { isNull } from "lodash";

interface AddContractProps {
  closeModal: (type: boolean) => void;
}
const AddContract: React.FC<AddContractProps> = ({ closeModal }) => {
  const project = useSelector((state: any) => state.Contract.project);

  const [selectedState, setSelectedState] = useState(
    project?.contract?.pc_state || ""
  );
  const [extraData, setExtradata] = useState<any>(null);
  const dispatch: any = useDispatch();
  let data = null;

  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  const handleExtraData = (data: object) => {
    console.log(data, "data");
    setExtradata(data);
  };

  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  const validationSchema = Yup.object().shape({
    additionalHomeownerName: Yup.string(),
    startDate: Yup.date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === null || originalValue === "" ? null : value
      )
      .min(new Date(), "Start date cannot be in the past")
      .required("Start date is required"),

    substantialCompletionDate: Yup.date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === null || originalValue === "" ? null : value
      )
      .required("Substantial completion date is required")
      .when("startDate", (startDate, schema) =>
        startDate && startDate instanceof Date && !isNaN(startDate.getTime())
          ? schema.min(
              startDate,
              "Substantial completion date cannot be before start date"
            )
          : schema
      ),

    endDate: Yup.date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === null || originalValue === "" ? null : value
      )
      .required("End date is required")
      .when("startDate", (startDate, schema) =>
        startDate && startDate instanceof Date && !isNaN(startDate.getTime())
          ? schema.min(startDate, "End date cannot be before start date")
          : schema
      )
      .when("substantialCompletionDate", (substantialCompletionDate, schema) =>
        substantialCompletionDate &&
        substantialCompletionDate instanceof Date &&
        !isNaN(substantialCompletionDate.getTime())
          ? schema.min(
              substantialCompletionDate,
              "End date cannot be before substantial completion date"
            )
          : schema
      ),

    projectCostModel: Yup.string().required("Project cost model is required"),
    allowances: Yup.string(),
    descriptionOfWork: Yup.string().required("Description of work is required"),
    escrowService: Yup.boolean(),
    contractorInsurance: Yup.string().required(
      "Contractor insurance is required"
    ),
    workersCompensationInsurance: Yup.string().required(
      "Workers' compensation insurance is required"
    ),
    documentName: Yup.string().required("Document name is required"),
  });

  const validation = useFormik({
    initialValues: {
      projectId: project?.p_id || "",
      homeownerName: project?.home_owner
        ? `${project.home_owner.user_first_name} ${project.home_owner.user_last_name}`
        : "",
      additionalHomeownerName:
        project?.contract?.pc_additional_home_owner_name || "",
      contractorName: project?.contractor
        ? `${project.contractor.user_first_name} ${project.contractor.user_last_name}`
        : "",
      projectAddress: project?.p_street_address || "",
      city: project?.p_city || "",
      state: project?.p_state || "",
      zip: project?.p_zip || "",
      startDate: null,
      substantialCompletionDate: null,
      endDate: null,
      projectCostModel: project?.contract?.pc_project_cost_model || "",
      insuranceProviderName:
        project?.contract?.pc_contractor_insurance_provider_name || "",
      totalSalesPrice: project?.p_cost || "",
      allowances: project?.contract?.pc_total_allowance || "",
      descriptionOfWork: project?.contract?.pc_work_description || "",
      escrowService: project?.p_escrow === "1",
      authorizedRepresentative:
        project?.contract?.pc_homeowner_authorized_representative || "",
      contractorAuthorizedRepresentative:
        project?.contract?.pc_contractor_authorized_representative || "",
      contractorInsurance:
        project?.contract?.pc_contractor_insurance_option || "",
      workersCompensationInsurance:
        project?.contract?.pc_worker_compensation_insurance_option || "",
      workersCompensationInsuranceLimit:
        project?.contract?.pc_worker_compensation_insurance_limit || "",
      extraData: extraData,
      homeownerId: project?.p_home_owner_id || "",
      documentName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values.extraData, "valuesextraData");
      setLoading(true);
      try {
        await dispatch(createHicContract(values));
        if (project !== "") {
          setLoading(true);
          dispatch(getSingleProjectById(project.p_id)).finally(() =>
            setLoading(false)
          );
        }
        closeModal(true);
        setNotification({
          status: true,
          message: "Contract Created Successfully",
          variant: "success",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error
        console.error("Error creating contract:", error);
      }
    },
  });

  useEffect(() => {
    validation.setFieldValue("extraData", extraData);
  }, [extraData]);

  const renderState = () => {
    switch (selectedState) {
      case "FL": {
        return <Florida onExtraData={handleExtraData} />;
      }
      case "MA": {
        return <Massuchusetts onExtraData={handleExtraData} />;
      }
      case "DE": {
        return <Delaware onExtraData={handleExtraData} />;
      }
    }
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{ position: "absolute", top: "5%", right: "24px", zIndex: 9 }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="">
          <Container>
            <Row className="p-5">
              <Col>
                <h4>ADD HOMEOWNER CONTRACT</h4>
                <h5>Home Improvement Contract</h5>
              </Col>
            </Row>
          </Container>
          <Form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Container>
              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Contract type</h5>
                  <p>Governing Law State *</p>
                  <Col lg={4}>
                    <Form.Select
                      className="fs-5"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      <option value="">Select State</option>
                      <option
                        value="CT"
                        selected={
                          project?.contract?.pc_state === "CT" ? true : false
                        }
                      >
                        Connecticut
                      </option>
                      <option value="FL" disabled>
                        Florida
                      </option>
                      <option value="MA" disabled>
                        Massachusetts
                      </option>
                      <option value="DE" disabled>
                        Delaware
                      </option>
                      <option value="OH">Ohio</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Homeowner details</h5>
                  <p>
                    Most details are already prefilled by a homeowner. You can
                    add additional homeowner name if you wish.
                  </p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="homeownerName"
                        name="homeownerName"
                        disabled
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.homeownerName || ""}
                        isInvalid={
                          validation.touched.homeownerName &&
                          !!validation.errors.homeownerName
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.homeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Additional Homeowner Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="additionalHomeownerName"
                        value={validation.values.additionalHomeownerName || ""}
                        onChange={validation.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.additionalHomeownerName &&
                          !!validation.errors.additionalHomeownerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Contractor details</h5>
                  <p>All necessary details are already prefilled.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>First and last name</Form.Label>
                      <Form.Control
                        type="text"
                        id="contractorName"
                        name="contractorName"
                        value={validation.values.contractorName || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h3>Project address</h3>
                  <p>All necessary details are already prefilled by you.</p>
                  <Form.Group className="mb-3">
                    <Form.Label>Street address</Form.Label>
                    <Form.Control
                      type="text"
                      id="projectAddress"
                      name="projectAddress"
                      value={validation.values.projectAddress || ""}
                      disabled
                      onChange={validation.handleChange}
                    />
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          id="city"
                          name="city"
                          value={validation.values.city || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          id="state"
                          name="state"
                          value={validation.values.state || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type="text"
                          id="zip"
                          name="zip"
                          value={validation.values.zip || ""}
                          disabled
                          onChange={validation.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Dates</h5>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className="w-100">Start date*</Form.Label>
                      <DatePicker
                        className={`form-control w-100 ${validation.touched.startDate && validation.errors.startDate ? "is-invalid" : ""}`}
                        selected={validation.values.startDate}
                        onChange={(date) =>
                          validation.setFieldValue("startDate", date)
                        }
                        onBlur={() => validation.setFieldTouched("startDate")}
                        dateFormat="MM/dd/yyyy"
                      />
                      {validation.touched.startDate &&
                      validation.errors.startDate ? (
                        <div className="invalid-feedback d-block">
                          {validation.errors.startDate}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="w-100">
                        Substantial completion date*
                      </Form.Label>
                      <DatePicker
                        className={`form-control w-100 ${validation.touched.substantialCompletionDate && validation.errors.substantialCompletionDate ? "is-invalid" : ""}`}
                        selected={validation.values.substantialCompletionDate}
                        onChange={(date) =>
                          validation.setFieldValue(
                            "substantialCompletionDate",
                            date
                          )
                        }
                        onBlur={() =>
                          validation.setFieldTouched(
                            "substantialCompletionDate"
                          )
                        }
                        dateFormat="MM/dd/yyyy"
                      />
                      {validation.touched.substantialCompletionDate &&
                      validation.errors.substantialCompletionDate ? (
                        <div className="invalid-feedback d-block">
                          {validation.errors.substantialCompletionDate}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="w-100">End date*</Form.Label>
                      <DatePicker
                        className={`form-control w-100 ${validation.touched.endDate && validation.errors.endDate ? "is-invalid" : ""}`}
                        selected={validation.values.endDate}
                        onChange={(date) =>
                          validation.setFieldValue("endDate", date)
                        }
                        onBlur={() => validation.setFieldTouched("endDate")}
                        dateFormat="MM/dd/yyyy"
                      />
                      {validation.touched.endDate &&
                      validation.errors.endDate ? (
                        <div className="invalid-feedback d-block">
                          {validation.errors.endDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Project Cost Model *</h5>
                  <p>Choose one option.</p>
                  <Col>
                    <Form.Check
                      label="Total Cost of the Project excluding any allowances agreed to between the parties"
                      type="radio"
                      name="projectCostModel"
                      value="Total Cost of the Project"
                      checked={
                        validation.values.projectCostModel ===
                        "Total Cost of the Project"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    <Form.Check
                      label="Cost of the Work plus the Contractor’s Fee"
                      type="radio"
                      name="projectCostModel"
                      value="Cost of the Work plus the Contractor’s Fee"
                      checked={
                        validation.values.projectCostModel ===
                        "Cost of the Work plus the Contractor’s Fee"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    <Form.Check
                      label="The work to be performed at the Construction Site / Project Site will be based upon time and materials"
                      type="radio"
                      name="projectCostModel"
                      value="Time and Materials"
                      checked={
                        validation.values.projectCostModel ===
                        "Time and Materials"
                      }
                      onChange={validation.handleChange}
                      className="mb-3"
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.projectCostModel &&
                        !!validation.errors.projectCostModel}
                    </Form.Control.Feedback>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Total Sales Price *</h5>
                  <p>All necessary details are already prefilled by you.</p>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Total Sales Price *</Form.Label>
                      <Form.Control
                        type="text"
                        id="totalSalesPrice"
                        name="totalSalesPrice"
                        value={validation.values.totalSalesPrice || ""}
                        disabled
                        onChange={validation.handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={10}>
                  <h5>Allowances</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Total allowances</Form.Label>
                    <Form.Control
                      type="text"
                      id="allowances"
                      name="allowances"
                      value={validation.values.allowances || ""}
                      onChange={validation.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.allowances &&
                        !!validation.errors.allowances}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={10}>
                  <h5>Description of work*</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Description of work*</Form.Label>
                    <Form.Control
                      as="textarea"
                      id="descriptionOfWork"
                      name="descriptionOfWork"
                      value={validation.values.descriptionOfWork || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.descriptionOfWork && (
                      <div className="text-danger">
                        {validation.errors.descriptionOfWork &&
                          !!validation.errors.descriptionOfWork}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Escrow Service</h5>
                  <p className="mb-2">Escrow</p>
                  <Form.Check
                    disabled
                    type="switch"
                    id="escrowService"
                    name="escrowService"
                    label="Enable Escrow Service"
                    checked={validation.values.escrowService}
                    onChange={validation.handleChange}
                  />
                  {validation.errors.escrowService &&
                    validation.touched.escrowService && (
                      <div className="text-danger">
                        {validation.errors.escrowService}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={5}>
                  <h5>Authorized Representatives</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Contractor Authorized Representative First and Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="contractorAuthorizedRepresentative"
                      name="contractorAuthorizedRepresentative"
                      value={
                        validation.values.contractorAuthorizedRepresentative ||
                        ""
                      }
                      onChange={validation.handleChange}
                    />
                    {validation.errors.contractorAuthorizedRepresentative && (
                      <div className="text-danger">
                        {validation.errors.contractorAuthorizedRepresentative &&
                          !!validation.errors
                            .contractorAuthorizedRepresentative}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Homeowner Authorized Representative First and Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="authorizedRepresentative"
                      name="authorizedRepresentative"
                      value={validation.values.authorizedRepresentative || ""}
                      onChange={validation.handleChange}
                    />
                    {validation.errors.authorizedRepresentative && (
                      <div className="text-danger">
                        {validation.errors.authorizedRepresentative &&
                          !!validation.errors.authorizedRepresentative}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col md={6}>
                  <h5>Contractor’s Insurance *</h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Carries commercial general liability insurance"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="yes"
                    checked={validation.values.contractorInsurance === "yes"}
                    onChange={validation.handleChange}
                  />
                  {validation.values.contractorInsurance === "yes" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Insurance Provider Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="insuranceProviderName"
                        value={validation.values.insuranceProviderName}
                        onChange={validation.handleChange}
                      />
                      {validation.errors.insuranceProviderName &&
                        validation.touched.insuranceProviderName && (
                          <div className="text-danger">
                            {validation.errors.insuranceProviderName &&
                              !!validation.errors.insuranceProviderName}
                          </div>
                        )}
                    </Form.Group>
                  )}
                  <Form.Check
                    label="The Contractor does not carry commercial general liability insurance"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="no"
                    checked={validation.values.contractorInsurance === "no"}
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="The Contractor is self-insured"
                    name="contractorInsurance"
                    type="radio"
                    className="mb-3"
                    value="selfInsured"
                    checked={
                      validation.values.contractorInsurance === "selfInsured"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.errors.contractorInsurance &&
                    validation.touched.contractorInsurance && (
                      <div className="text-danger">
                        {validation.errors.contractorInsurance &&
                          !!validation.errors.contractorInsurance}
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="bg-white p-5 mb-5 rounded">
                <Col>
                  <h5>Worker's Compensation Insurance *</h5>
                  <p>Choose one option.</p>
                  <Form.Check
                    label="Contractor has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations"
                    name="workersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="noEmployees"
                    checked={
                      validation.values.workersCompensationInsurance ===
                      "noEmployees"
                    }
                    onChange={validation.handleChange}
                  />
                  <Form.Check
                    label="Contractor carries Workers’ Compensation Insurance for all employees"
                    name="workersCompensationInsurance"
                    type="radio"
                    className="mb-3"
                    value="hasInsurance"
                    checked={
                      validation.values.workersCompensationInsurance ===
                      "hasInsurance"
                    }
                    onChange={validation.handleChange}
                  />
                  {validation.values.workersCompensationInsurance ===
                    "hasInsurance" && (
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Worker's Compensation Insurance Limit *
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="workersCompensationInsuranceLimit"
                            value={
                              validation.values
                                .workersCompensationInsuranceLimit
                            }
                            onChange={validation.handleChange}
                          />
                          {validation.errors
                            .workersCompensationInsuranceLimit &&
                            validation.touched
                              .workersCompensationInsuranceLimit && (
                              <div className="text-danger">
                                {validation.errors
                                  .workersCompensationInsuranceLimit &&
                                  !!validation.errors
                                    .workersCompensationInsuranceLimit}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {validation.errors.workersCompensationInsurance &&
                    validation.touched.workersCompensationInsurance && (
                      <div className="text-danger">
                        {validation.errors.workersCompensationInsurance &&
                          !!validation.errors.workersCompensationInsurance}
                      </div>
                    )}
                </Col>
              </Row>

              {renderState()}
              <Row className="bg-white p-5 mb-3 rounded">
                <Col>
                  <h5>Document name *</h5>
                  <Row>
                    <p>
                      Please indicate what you would like the contract’s PDF
                      file name to be. This name will be shared with all signing
                      parties *
                    </p>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          id="documentName"
                          name="documentName"
                          value={validation.values.documentName || ""}
                          onChange={validation.handleChange}
                        />
                        {validation.errors.documentName && (
                          <div className="text-danger">
                            {validation.errors.documentName}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="p-4 rounded">
                <Col className="d-flex justify-content-center">
                  <Button type="submit" variant="primary">
                    Create Contract
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddContract;
