//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../helpers/fakebackend_helper";

import { apiError, apiSuccess, contractApproveSuccess, singleContractSuccess, contractorCountSuccess, FormSuccess, subscribersSuccess } from './reducer';

import apiUrl from "slices/apiEndpoint";

export const getAllContractors = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${apiUrl}/admin/get-all-contractors`);
    let data = response.data;
    //console.log(data.count, "count")
    if (data) {
      dispatch(apiSuccess(data.data));
      dispatch(contractorCountSuccess(data.count))
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};


export const getAdminContractorById = (contId:any) => async (dispatch: any) => {
  try { 
    const response = await axios.get(`${apiUrl}/admin/get-contractors/${contId}`)
   let data = response.data;
    //console.log(data, "ddd")
    if (data) {
      console.log(data, "datadata")
      //console.log(data[0], "ddd")
        dispatch(singleContractSuccess(data));
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const approveContractor = (approve:any, contId:any) => async (dispatch: any) => {
  try { 
    const response = await axios.put(`${apiUrl}/admin/approve-contractor/${contId}`, {
      isApprove:approve
    })
   let data = response.data;
    if (data) {
        dispatch(contractApproveSuccess("Approved"));
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};


export const getSubscribers = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${apiUrl}/subscribe/get-subscribers`);
    let data = response.data;
    if (data) {
      dispatch(subscribersSuccess(data.subscribers))
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const getFormData = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${apiUrl}/subscribe/get-form`);
    let data = response.data;
    if (data) {
      dispatch(FormSuccess(data.formsData))
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};




function getDecodeId() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userId
}
 