import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Table,
  Modal,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import BannerTutorial from "./components/banner";
import TutorialBanner from "../../../../assets/images/tutorial/tradesmens-banner-t.png";
import SlidderBanner from "../../../../assets/images/tutorial/slider-banner.png";
import featureIcon from "../../../../assets/images/tutorial/icon/feature-icon.png";
import unlimitedContracts from "../../../../assets/images/tutorial/icon/contract-agreement.png";
import milestonesIcon from "../../../../assets/images/tutorial/icon/milestone.png";
import paymentsIcons from "../../../../assets/images/tutorial/icon/payment.png";
import downloadIcon from "../../../../assets/images/tutorial/icon/file.png";
import privacyIcon from "../../../../assets/images/tutorial/icon/compliant.png";
import marketIcon from "../../../../assets/images/tutorial/icon/content-strategy.png";
import subContractorsIcon from "../../../../assets/images/tutorial/icon/workers.png";
import eSign from "../../../../assets/images/tutorial/icon/sign.png";
import websiteIcon from "../../../../assets/images/tutorial/icon/application1.png";
import serverIcon from "../../../../assets/images/tutorial/icon/cloud-server.png";

import IntroContent from "./components/intro-content";
import Features from "./components/features";
import VideoSection from "./components/video-section";
import VideoOverLay from "../../../../assets/images/tutorial/video-overlay.png";
import OfferSlider from "./components/offer-slider";
import TermsCondition from "./components/terms-condition";
import SubmitButton from "./components/submit-button";
import {
  acceptContractTerms,
  getSubscribedUser,
  handleStripePackage,
  cancelSubscription,
} from "../../../../slices/thunk";
import Pricing from "./components/pricing";

interface ContractListProps {
  handleStatus: (values: any) => void;
}

interface JwtPayload {
  userType: string;
}

const Tutorial: React.FC<ContractListProps> = ({ handleStatus }) => {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  useEffect(() => {
    document.title = "Tradesmen Contract Builder";
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const handleTermsCondition = (value: any) => {
    console.log(value, "termss");
    dispatch(acceptContractTerms(value)).then((resp: any) => {
      if (resp) {
        setNotification({
          status: true,
          message: "Terms Updated Successfully",
          variant: "success",
        });
      }
    });
  };

  const handleAlertClick = () => {
    setNotification(null);
  };
  const introContentHtml = `<p style="font-size:14px; text-align:center;"><span style="color:#3762EA; font-weight:bold">The Tradesmen’s Collective</span> has made it easier for all the Contractors to develop digital contracts and share it with Homeowners.</p>
                        <p style="font-size:14px; text-align:center;">Enjoy the unlimited benefits of our highly designed and state of the art Contract Automation Software on your <span style="color:#3762EA; font-weight:bold">30-Days free trial package<span></p>`;

  const featuresData = [
    { icon: unlimitedContracts, feature: "Create Unlimited Contracts" },
    { icon: milestonesIcon, feature: "Add Milestones" },
    { icon: paymentsIcons, feature: "Set Up & Schedule Payments" },
    { icon: downloadIcon, feature: "Free Downloads and share" },
    { icon: subContractorsIcon, feature: "Add Sub-contractors" },
    { icon: websiteIcon, feature: "Get your dedicated Website" },
    { icon: privacyIcon, feature: "Maintain complete Privacy" },
    { icon: eSign, feature: "E-Docu Signing" },
    { icon: serverIcon, feature: "AWS based Servers for highend Security" },
    { icon: marketIcon, feature: "Market your business on Social Media" },
  ];
  const videoContent = `Explore the journey and see how it actually functions, so you can make the best out of it. Tutorials are always useful. Demonstrate the process by just simply watching this tutorial video.`;
  const banners = [
    { banner: SlidderBanner, hurryUp: true, alt: "Offer Banner" },
  ];

  const handleStripePc = (type: any) => {
    setLoading(true);
    let values = {
      package: type,
      email: decoded.email,
    };
    dispatch(handleStripePackage(values)).finally(() => {
      setLoading(false);
    });
  };
  const handleCancelSubscription = () => {
    setLoading(true);
    dispatch(cancelSubscription()).then((resp: any) => {
      dispatch(getSubscribedUser());
      setNotification({
        status: true,
        message: resp.message,
        variant: "success",
      });
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{ position: "absolute", top: "5%", right: "24px", zIndex: 9 }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <BannerTutorial
            banner={TutorialBanner}
            title={"Welcome to the"}
            subTitle={"Tradesmen’s Collective"}
            titleColor={"#2B2B2B"}
            subTitleColor={"#1C4ED7"}
          />
          <IntroContent content={introContentHtml} />
          <Features featureTitle={"Features"} featuresList={featuresData} />
          <VideoSection
            title="Tutorial"
            videoTitle="How to Use It?"
            videoContent={videoContent}
            videoSrc="https://res.cloudinary.com/dvnxszfqa/video/upload/v1721041978/Tradesmen_Admin_3_vdik5v.mp4"
            overlayImageSrc={VideoOverLay}
          />
          <TermsCondition handleTerm={handleTermsCondition} />
          <Pricing
            handleCancel={handleCancelSubscription}
            handleStartTrial={handleStripePc}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Tutorial;
