import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Accordion,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createMileStones,
  getSingleProjectById,
  sendDocToS3,
  requestInspection,
} from "../../../../slices/thunk";
import Dropzone from "react-dropzone";

interface Milestone {
  id: any;
  title: string;
  name: string;
  percentage: number;
  isDisable: boolean;
  inspectionUrl: string;
  isCompleted: boolean;
  status: string;
}

interface MilestonesProps {
  milestonesData: any;
  onCreateProject: Function;
  disable: any;
}

const NonEscrowMilestones: React.FC<MilestonesProps> = ({
  milestonesData,
  onCreateProject,
  disable,
}) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const project = useSelector((state: any) => state.Contract.project);
  const [selectedState, setSelectedState] = useState("");
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const [formData, setFormData] = useState<any>({
    totalCost: project && project.p_total_cost ? project.p_total_cost : "",
  });
  const [milestones, setMilestones] = useState<Milestone[]>(() => {
    if (project && project.milestones.length) {
      return project.milestones.map((milestone: any, index: number) => ({
        id: milestone.pm_id,
        title: `Milestone ${index + 1}`,
        name: milestone.pm_name,
        percentage: milestone.pm_percentage,
        isDisable: false,
        inspectionUrl: "",
        isCompleted: false,
        status: "",
      }));
    } else {
      return [
        {
          id: "",
          title: "Milestone 1",
          name: "",
          percentage: 0,
          isDisable: false,
          inspectionUrl: "",
          isCompleted: false,
          status: "",
        },
      ];
    }
  });
  const [projectId, setProjectId] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const handleMilestoneChange = (
    index: number,
    field: keyof Milestone,
    value: string | number | boolean
  ) => {
    const updatedMilestones: any = [...milestones];
    updatedMilestones[index][field] = value;
    setMilestones(updatedMilestones);
  };

  const handleAcceptedFiles = (files: any, index: number) => {
    files.forEach((file: any) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });

      // Assuming sendDocToS3 returns a promise with the URL of the uploaded document
      dispatch(sendDocToS3("inspectionDocument", file)).then((url: string) => {
        const updatedMilestones = [...milestones];
        updatedMilestones[index].inspectionUrl = url;
        setMilestones(updatedMilestones);
      });
    });
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  useEffect(() => {
    if (!location.search) {
      console.log("No search parameters found");
      return;
    }

    const queryString = location.search.substring(1);
    const [mainQueryString, nestedQueryString] = queryString.split("?");
    const mainParams: any = new URLSearchParams(mainQueryString);

    let nestedParams: any = null;
    if (nestedQueryString) {
      nestedParams = new URLSearchParams(nestedQueryString);
    }
    const id = nestedParams ? nestedParams.get("id") : null;
    setProjectId(id);
  }, [location.search]);

  const handleSubmit = () => {
    const milestonesData = milestones.map((milestone) => ({
      id: milestone.id ? milestone.id : null,
      name: milestone.name,
      percentage: milestone.percentage,
    }));

    const totalPercentage = milestones.reduce(
      (total, milestone) => total + milestone.percentage,
      0
    );

    const formDataJSON = {
      projectId: projectId,
      totalCost: formData.totalCost,
      milestones: milestonesData,
    };
    dispatch(createMileStones(formDataJSON));
    onCreateProject(3, projectId);
    if (project !== "") {
      dispatch(getSingleProjectById(project.p_id));
    }
    setNotification({
      status: true,
      message: "Milestones Created Successfully",
      variant: "success",
    });
    console.log(formDataJSON, "formDataJSON");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRequestInspection = async (index: number) => {
    setLoading(true);
    const milestone = milestones[index];
    let payload = {
      milestoneId: milestone.id,
      documentUrl: milestone.inspectionUrl,
      isCompleted: milestone.isCompleted,
      type: milestone.status,
    };

    try {
      await dispatch(requestInspection(payload));
      setNotification({
        status: true,
        message: "Request Created Successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Failed to create request:", error);
      setNotification({
        status: true,
        message: "Failed to create request",
        variant: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const addMilestone = () => {
    setMilestones((prevMilestones) => [
      ...prevMilestones,
      {
        id: "",
        title: `Milestone ${prevMilestones.length + 1}`,
        name: "",
        percentage: 0,
        isDisable: false,
        inspectionUrl: "",
        isCompleted: false,
        status: "",
      },
    ]);
  };

  const removeMilestone = (index: number) => {
    setMilestones((prevMilestones) =>
      prevMilestones.filter((_, i) => i !== index)
    );
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{ position: "absolute", top: "5%", right: "24px", zIndex: 9 }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Card>
        <Card.Body>
          <h5 className="card-title mb-3">Add Milestones</h5>
          <Row className="g-3 align-items-center mb-3">
            <Col lg={12}>
              <div>
                <Form.Label htmlFor="course-title-input">
                  Total cost (i.e. your part of the total project cost)
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="course-title-input"
                  placeholder="Total cost"
                  value={formData.totalCost}
                  onChange={(e) =>
                    setFormData({ ...formData, totalCost: e.target.value })
                  }
                  required
                  disabled={disable}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <p className="mb-5">Please indicate names of your milestones.</p>

          {milestones.map((milestone, index) => (
            <Row
              className="g-3 align-items-center mb-3"
              key={milestone.id || index}
            >
              <Col lg={12}>
                <Accordion
                  defaultActiveKey={index.toString()}
                  className="custom-accordionwithicon"
                >
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{milestone.title}</Accordion.Header>
                    <Accordion.Body>
                      <Row className="g-3 align-items-center mb-3">
                        <Col lg={12}>
                          <div>
                            <Form.Label htmlFor={`milestone-name-${index}`}>
                              Name of Milestone
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id={`milestone-name-${index}`}
                              placeholder="Milestone name"
                              value={milestone.name}
                              onChange={(e) =>
                                handleMilestoneChange(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                              required
                              disabled={disable}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="g-3 align-items-center mb-3">
                        <Col lg={12}>
                          <div>
                            <Form.Label
                              htmlFor={`milestone-percentage-${index}`}
                            >
                              Percentage of your fee
                            </Form.Label>
                            <Form.Control
                              type="number"
                              id={`milestone-percentage-${index}`}
                              placeholder="Percentage"
                              value={milestone.percentage}
                              disabled={milestone.isDisable}
                              onChange={(e) =>
                                handleMilestoneChange(
                                  index,
                                  "percentage",
                                  parseInt(e.target.value)
                                )
                              }
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      {project && project.p_status === "LIVE" && (
                        <Row className="g-3 align-items-center mb-3">
                          <Col lg={12}>
                            <div>
                              <Form.Label htmlFor={`Completed-${index}`}>
                                Completed
                              </Form.Label>
                              <div className="form-check form-switch mb-3">
                                <Form.Check>
                                  <Form.Check.Input
                                    type="checkbox"
                                    role="switch"
                                    id={`is-completed-${index}`}
                                    name={`is-completed-${index}`}
                                    onChange={(e) =>
                                      handleMilestoneChange(
                                        index,
                                        "isCompleted",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </Form.Check>
                              </div>

                              <h4>Request a Tradesmen Inspection</h4>
                              <p>
                                By requesting an inspection you are confirming
                                that any necessary inspections from the relevant
                                bodies have been completed.
                              </p>
                              <Col lg={4}>
                                <Form.Select
                                  className="fs-5"
                                  value={milestone.status}
                                  onChange={(e) =>
                                    handleMilestoneChange(
                                      index,
                                      "status",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Start">Start</option>
                                  <option value="Rough">Rough</option>
                                  <option value="Final">Final</option>
                                </Form.Select>
                              </Col>

                              {milestone.inspectionUrl !== "" ? (
                                <Col lg="12">
                                  <img
                                    src={milestone.inspectionUrl}
                                    alt="blueprint"
                                  />
                                  <Button
                                    className="danger"
                                    onClick={() =>
                                      handleMilestoneChange(
                                        index,
                                        "inspectionUrl",
                                        ""
                                      )
                                    }
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              ) : (
                                <Col lg="12">
                                  <div>
                                    <p>Upload your inspection documentation</p>
                                    <Dropzone
                                      onDrop={(acceptedFiles: any) =>
                                        handleAcceptedFiles(
                                          acceptedFiles,
                                          index
                                        )
                                      }
                                    >
                                      {({ getRootProps }: any) => (
                                        <div
                                          className="dropzone course-dropzone border border-1 border-dashed text-center"
                                          {...getRootProps()}
                                        >
                                          <div className="dz-message needsclick">
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                            </div>
                                            <h4>Upload a file</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                </Col>
                              )}
                            </div>
                          </Col>
                          <Col lg="4">
                            <Button
                              variant="primary"
                              onClick={() => handleRequestInspection(index)}
                            >
                              Submit Request
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Button
                        variant="danger"
                        onClick={() => removeMilestone(index)}
                      >
                        Remove Milestone
                      </Button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          ))}
          {project && project.p_status === "LIVE" ? (
            ""
          ) : (
            <Button variant="primary" onClick={addMilestone}>
              Add Milestone
            </Button>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex align-items-start gap-3 mt-4">
        <button type="button" className="btn btn-light btn-label previestab">
          <i className="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>{" "}
          Back to Project Detail
        </button>
        <button
          type="button"
          className="btn btn-success btn-label right ms-auto nexttab nexttab"
          onClick={() => handleSubmit()}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>{" "}
          Add Subcontractor
        </button>
      </div>
    </React.Fragment>
  );
};

export default NonEscrowMilestones;
