import React, { useEffect, useState } from "react";
import { Card, Col, Button, Form, Alert, Spinner } from "react-bootstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, resetLoginFlag,otpVerification } from "../../slices/thunk";
import { createSelector } from "reselect";

//import images

const Signin = (props: any) => {
  const profileStatus = useSelector((state: any) => state.UserProfile.userProfileStatus);

  const errorApi = useSelector((state: any) => state.Login.error)
  console.log(errorApi, "errorApierrorApi")
   
  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account, login) => ({
      user: account.user,
      error: login.error,
      loading: login.loading,
      errorMsg: login.errorMsg,
    })
  );

  const { user, error, loading, errorMsg } = useSelector(selectAccountAndLogin);

  const [userLogin, setUserLogin] = useState<any>([]);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [otpShow, setOtpShow] = useState<boolean>(false);
  // Add loading state
  const [formLoading, setFormLoading] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("authUser", JSON.stringify(token));
      // Redirect to dashboard or any desired page
      if (profileStatus) {
        props.router.navigate("/dashboard");
      }
      else { 
        props.router.navigate("/pages-profile-settings");
      }
    }
  }, [props.router]);
  useEffect(() => {
    if (user && user) {
      const updatedUserData =
        process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? user.multiFactor.user.email
          : user.email;
      const updatedUserPassword =
        process.env.REACT_APP_DEFAULTAUTH === "firebase" ? "" : user.password;
      setUserLogin({
        email: updatedUserData,
        password: updatedUserPassword,
      });
    }
  }, [user]);

  
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email")
    }),
    onSubmit: (values) => {
        setFormLoading(true); // Set loading state to true

        dispatch(loginUser(values, props.router.navigate))
          .then((data: any) => {
            if (data) {
              setOtpShow(true);
            }
          })
          .catch((error: any) => {
          })
          .finally(() => {
            setFormLoading(false); // Reset loading state
          });
      },
});

const validationOtp: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: userLogin.otp,
    },
    validationSchema: Yup.object({
      otp: Yup.string().required('OTP Required'),
    }),
    onSubmit: (values) => {
        setFormLoading(true); // Set loading state to true

    dispatch(otpVerification(values, props.router.navigate))
      .finally(() => {
        setFormLoading(false); // Reset loading state
      });
  },
});


  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  //for facebook and google authentication
  const socialResponse = (type: any) => {
    signIn(type);
  };

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);
  document.title = "Login";
  return (
    <ParticlesAuth>
      <React.Fragment>
        <Col xxl="6" className="mx-auto">
          <Card className="mb-0 border-0 shadow-none mb-0">
            <Card.Body className="p-sm-5 m-lg-4">
              <div className="text-center mt-5">
                <h5 className="fs-3xl">Welcome Back</h5>
                <p className="text-muted">Sign in to continue</p>
              </div>
              <div className="p-2 mt-5">
                {error && error ? (
                  <Alert variant="danger"> {error} </Alert>
                ) : null}

                <Form
                  action="#"
                  onSubmit={(e) => {
                    e.preventDefault();
                    {!otpShow ? validation.handleSubmit() : validationOtp.handleSubmit() }
                    return false;
                  }}
                >
                    {!otpShow ? 
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="email"
                        name="email"
                        className="form-control password-input"
                        placeholder="Enter Registered Email"
                        required
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        isInvalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                    </div>
                    {validation.touched.email && validation.errors.email ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.email}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
:""}
              
                  {otpShow ? (
                    <Form.Group className="mb-3" controlId="formPassword">
                      <Form.Label>
                        One Time Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Form.Control
                          type={"text"}
                          className="form-control pe-5 password-input "
                          placeholder="Enter OTP"
                          required
                          name="otp"
                          value={validationOtp.values.otp || ""}
                          onChange={validationOtp.handleChange}
                          onBlur={validationOtp.handleBlur}
                          isInvalid={
                            validationOtp.touched.otp && validationOtp.errors.otp
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Form.Group>
                  ) : (
                    ""
                  )}

                  <Form.Group controlId="formRememberMe">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                      id="auth-remember-check"
                    />
                  </Form.Group>

                  <div className="mt-4">
                    {}
                    <Button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={loading || formLoading} // Disable button during loading
                    >
                      {formLoading && <Spinner size="sm" />}  Sign In
                    </Button>
                  </div>
                  
                  
                </Form>

               
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default withRouter(Signin);
